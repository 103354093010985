import { Button, Card, Grid, IconButton, makeStyles, Popover, TextField, Typography } from '@material-ui/core';
import { SelectBox } from "../../../components";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { actions } from "primary_care_admin_binder";
import { useHistory } from "react-router";
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertProps } from '../../../utils';
import { AlertContext } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
    req_red: {
      color: "red",
    },
    dot: {
      height: "7px",
      width: "7px",
      backgroundColor: "#bbb",
      borderRadius: "50%",
      display: "inline-block",
      marginTop: "5px",
      marginRight: "7px",
      marginLeft: "7px",
    },
    grayfont: {
      fontSize: "12px",
      color: "gray",
    },
    line: {
      borderLeft: "3px solid #e1e1e1",
      marginRight: "8px",
      marginLeft: "8px",
    },
  }));
const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
}
const AppointmentDurationCard = (props) => {
    const history = useHistory();
  const dispatch = useDispatch();
  const { labelStyle, borderStyle, errorBorderStyle } = styles;
  const {parent_id} = props
  const service = useSelector((state) => state.resourceByAppointmentTypesSlice);
  const {
    state = {},
    handlechange,
    ResourceTypeAuto,
    ResourceRoleAuto,
    ResourceDepartmentAuto,
    ResourceNameAuto,
    deleteResource,
    savefunction,
    handleEdit,
    handleUpdate,
    setState,
    setFinalValue,
    finalValue,
    TableReadData,
  } = props;
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const [snackAlert, setSnackAlert] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editshow, seteditshow] = useState(false);
  const [valueshow, setvalueshow] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [resourceAndServiceDropDown, setresourceAndServiceDropDown] = useState();
  const [resourse, setResourse] = useState({
    apServiceType: [],
    apServiceCategory: [],
    apServiceCode: [],
    resourcetype: "",
    resourcerole: "",
    resourcedepartment: [],
    resourcename: [],
    duration: null,
  });
  const [error, setError] = useState(false);
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentEditIndex(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setResourse({
      servicetype: [],
      servicecategory: [],
      servicecode: [],
      resourcetype: "",
      resourcerole: "",
      resourcedepartment: [],
      resourcename: [],
    });
    // handlechange({
    //   ResourceName: [],
    //   ResourceDepartment: [],
    //   ResourceRole: [],
    //   resourcedefinition: [],
    // });
  };
  const handleChangelist = async (key, value) => {
    setResourse({
      ...resourse,
      [key]: value,
    });
    if (key == "resourcetype") {
      let resRoleArr = []
      finalValue?.resourcedefinition?.map((v,i)=>{
        if(v?.resourcetype?._id === value?._id){
          resRoleArr.push(v?.resourcerole)
        }
      })
      setresourceAndServiceDropDown({
        ...resourceAndServiceDropDown,
        resourceRoleList: resRoleArr
      })
      setResourse({
        ...resourse,
        [key]: value,
        resourcerole:{},
        resourcedepartment:[],
        resourcename:[]
      });
      return;
    }

    if (key === "resourcerole") {
      let resDepArr = []
      finalValue?.resourcedefinition?.map((v,i)=>{
        if(resourse?.resourcetype?._id === v?.resourcetype?._id && v?.resourcerole?._id === value?._id){
          v?.resourcedepartment?.map((val,i)=>{
            resDepArr.push(val);
          })
        }
      })
      setresourceAndServiceDropDown({
        ...resourceAndServiceDropDown,
        resourceDepList: resDepArr
      })
      setResourse({
        ...resourse,
        [key]: value,
        resourcedepartment:[],
        resourcename:[]
      });
      return;
    }

    if (key == "resourcedepartment") {
      let isLoad = true;
      if(isLoad){
        let resNameArr = []
        finalValue?.resourcedefinition?.map((v,i)=>{
          if(resourse?.resourcetype?._id === v?.resourcetype?._id
            && resourse?.resourcerole?._id === v?.resourcerole?._id
          ){
            v?.resourcename?.map((val,i)=>{
              resNameArr.push(val);
            })
          }
        })
        setresourceAndServiceDropDown({
          ...resourceAndServiceDropDown,
          resourceNameList: resNameArr
        })
        isLoad = false;
        setResourse({
          ...resourse,
          [key]: value,
          resourcename:[],
        });
        return;
      }
      if (resourse?.resourcedepartment?.length > 0) {
        const seen = new Set();
        const filteredArr = value.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        setResourse({
          ...resourse,
          [key]: filteredArr,
        })
      }
    }
    if (key == "resourcename"){
      if (resourse?.resourcename?.length > 0) {
        const seen = new Set();
        const filteredArr = value.filter((el) => {
          const duplicate = seen.has(el.label);
          seen.add(el.label);
          return !duplicate;
        });
        setResourse({
          ...resourse,
          [key]: filteredArr,
        })
      }
    }
  };

  const handleEditvalue = (v, i) => {
    seteditshow(true);
    setCurrentEditIndex(i);
    setResourse({
      resourcetype: v?.resourcetype,
      resourcerole: v?.resourcerole,
      resourcedepartment: v?.resourcedepartment,
      resourcename: v?.resourcename,
      apServiceType: v?.apServiceType,
      apServiceCategory: v?.apServiceCategory,
      apServiceCode: v?.apServiceCode,
      duration: v?.duration,
    });
    // if(v?.resourcetype?._id && v?.resourcerole?._id && v?.resourcedepartment?.[0]?._id){
    //   dispatch(
    //     actions.RES_RESOURCE_NAME_LIST_DOC_QDM({
    //       resourcetypeId: v?.resourcetype?._id,
    //       resourceroleId: v?.resourcerole?._id,
    //       resourcedepartmentId: v?.resourcedepartment?.[0]?._id,
    //     })
    //   );
    // }
    // if(v?.resourcetype?._id && v?.resourcerole?._id){
    //   dispatch(
    //     actions.RES_RESOURCE_DEPARTMENT_LIST_DOC_QDM({
    //       resourcetypeId: v?.resourcetype?._id,
    //       resourceroleId: v?.resourcerole?._id,
    //     })
    //   );
    // }
    // if(v?.resourcetype?._id){
    //   dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC_QDM(v?.resourcetype?._id));
    // }
    let resRoleArr = []
    finalValue?.resourcedefinition?.map((val,i)=>{
      if(val?.resourcetype?._id === v?.resourcetype?._id){
        resRoleArr.push(val?.resourcerole)
      }
    })
    let resDepArr = []
    finalValue?.resourcedefinition?.map((val,i)=>{
      if(v?.resourcetype?._id === val?.resourcetype?._id && val?.resourcerole?._id === v?.resourcerole?._id){
        val?.resourcedepartment?.map((value,i)=>{
          resDepArr.push(value);
        })
      }
    })
    let resNameArr = []
    finalValue?.resourcedefinition?.map((val,i)=>{
      if(v?.resourcetype?._id === val?.resourcetype?._id
        && v?.resourcerole?._id === val?.resourcerole?._id
      ){
        val?.resourcename?.map((value,i)=>{
          resNameArr.push(value);
        })
      }
    })
    setresourceAndServiceDropDown({
      ...resourceAndServiceDropDown,
      resourceRoleList: resRoleArr,
      resourceDepList: resDepArr,
      resourceNameList: resNameArr
    })
  };

  const deleteResources = (index) => {
    setFinalValue((prevFinalValue) => {
      const updatedResourceDefinition = [...prevFinalValue.resourcedefinition];
      updatedResourceDefinition.splice(index, 1);
      return {
        ...prevFinalValue,
        resourcedefinition: updatedResourceDefinition,
      };
    });

    setvalueshow((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };

  const handlesubmit = () => {
    if (currentEditIndex !== null) {
      setFinalValue((prevFinalValue) => {
        const updatedResourceDefinition = [
          ...prevFinalValue.appointmentDuration,
        ];
        updatedResourceDefinition[currentEditIndex] = resourse; // Update at the same index
        return {
          ...prevFinalValue,
          appointmentDuration: updatedResourceDefinition,
        };
      });
      const updatedValues = [...valueshow];
      updatedValues[currentEditIndex] = resourse;
      setvalueshow(updatedValues);
      setResourse({
        resourcetype: "",
        resourcerole: "",
        resourcedepartment: [],
        resourcename: [],
      });
      setCurrentEditIndex(null);
      setAnchorEl(null);
      seteditshow(false);
    } else {
      if (
        resourse?.duration
      ) {
        setError(false)
        const updatedValues = [...valueshow, resourse];
        setvalueshow(updatedValues);
        setFinalValue((prevFinalValue) => {
          return {
            ...prevFinalValue,
            appointmentDuration: [
              ...prevFinalValue.appointmentDuration,
              resourse,
            ], 
          };
        });
      } else {
        setError(true)
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      setResourse({
        resourcetype: "",
        resourcerole: "",
        resourcedepartment: [],
        resourcename: [],
      });
      setAnchorEl(null);
    }
  };
  const getServiceCode = async (servType,servCat) => {
    try {
      if(servType && servCat){
        let apServCodeArr = []
        finalValue?.servicedefinition?.map((v,i)=>{
          if(v?.servicetype?._id === servType && v?.servicecategory?._id === servCat){
            apServCodeArr.push(v?.servicecode)
          }
        })
        setresourceAndServiceDropDown({
          ...resourceAndServiceDropDown,
          apServiceCodeList : apServCodeArr
        })
      }
    } catch (e) {
      console.log(e)
    }
  } 
  useEffect(()=>{
    if(finalValue){
      //resourcedefinition servicedefinition  
      let servTypeArr = []
      let servCatArr = []
      let servCodeArr = []

      let resTypeArr = []
      let resRoleArr = []
      let resDepArr = []
      let resNameArr = []

      const resTypeSet = new Map();
      finalValue?.servicedefinition?.map((v,i)=>{
          servTypeArr.push(v?.servicetype);
          servCatArr.push(v?.servicecategory);
          // servCodeArr.push(v?.servicecode);
      })
      finalValue?.resourcedefinition?.forEach((v) => {
        if (v?.resourcetype) {
            const key = v.resourcetype.value;
            if (!resTypeSet.has(key)) {
              resTypeSet.set(key, v.resourcetype);
            }
        }
      });
      resTypeArr = Array.from(resTypeSet?.values());
      setresourceAndServiceDropDown({
        serviceTypeList : servTypeArr,
        serviceCategoryList : servCatArr,
        // serviceCodeList : servCodeArr,
        resourceTypeList : resTypeArr,
        // resourceRoleList : resRoleArr,
        // resourceDepList : resDepArr,
        // resourceNameList : resNameArr
      })
    }
  },[finalValue])

  useEffect(()=>{
    getServiceCode(resourse?.apServiceType?._id,resourse?.apServiceCategory?._id)
  },[resourse?.apServiceType?._id,resourse?.apServiceCategory?._id])
  useEffect(() => {
    const resourceData = TableReadData[0];
    if (history?.location?.state?.IsEdit && resourceData ) {
      let resourcedefinitionarry = [];
      resourceData?.appointmentduration?.map((val) => {
        const mappedData = {
          resourcetype: {
            _id: val?.resourcetype?._id || "",
            label: val?.resourcetype?.display,
            Value: val?.resourcetype?.display,
          },
          resourcerole: {
            _id: val?.resourcerole?._id,
            label: val?.resourcerole?.display,
            Value: val?.resourcerole?.display,
          },
          resourcedepartment:
            val.resourcedepartment.map((department) => ({
              _id: department._id,
              label: department.display,
              value: department.display,
            })) || [],
          resourcename:
            val?.resourcename.map((name) => ({
              _id: name._id,
              label: name.display,
              value: name.display,
            })) || [],
          duration: val?.durationinmin,
          apServiceType: {
            _id: val?.servicegrouptype?._id,
            label: val?.servicegrouptype?.display,
            Value: val?.servicegrouptype?.display,
          },
          apServiceCategory: {
            _id: val?.servicecategory?._id,
            label: val?.servicecategory?.display,
            Value: val?.servicecategory?.display,
          },
          apServiceCode: {
            _id: val?.servicecode?._id,
            label: val?.servicecode?.display,
            Value: val?.servicecode?.display,
          }
        };
        resourcedefinitionarry.push(mappedData);
      });
      setvalueshow(resourcedefinitionarry);
    }
  }, [TableReadData]);

    return (
        <>
          <Card
            style={{
              padding: "18px",
              width: "95%",
              marginLeft: "16px",
              marginTop: "20px",
              borderRadius: "10px",
            }}
          >
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8} style={{ display: "flex" }}>
                  <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                  APPOINTMENT DURATION
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Button
                      //   className={classes.newRes}
                      // color="primary"
                      onClick={handleClick}
                      style={{
                        marginTop: "-6px",
                        color: "#0071F2",
                        fontFamily: "poppinsemibold",
                      }}
                      button
                      disableTouchRipple
                      id={"resource_definition_card_Id"}
                    >
                      + Add New Appointment Duration
                    </Button>
                    <div style={{ marginBottom: "8px" }}>
                      {/* <img
                      src={expandarrow}
                      alt="icon"
                      style={{
                        transform: show ? "rotate(180deg)" : "rotate(359deg)",
                      }}
                      /> */}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Card
              style={{
                marginTop: "5px",
                borderRadius: "5px",
              }}
            >
              {valueshow?.length > 0 &&
                valueshow?.map((v, i) => {
                  return (
                    <Grid
                      container
                      style={{
                        border: "1px solid #E0E0E0",
                        padding: 12,
                        // marginBottom: 8,
                        backgroundColor: "#F9F9F9",
                        position: "relative",
                      }}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={1}
                            style={{
                              backgroundColor: "#E0E0E0",
                              position: "absolute",
                              left: 0,
                              padding: "6px 12px",
                              borderTopRightRadius: 9,
                              borderBottomRightRadius: 9,
                            }}
                          >
                            {i + 1}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginLeft: "4%",
                              display: "flex",
                              padding: 10,
                            }}
                          >
                            {/* <div>{v.type.label}</div>
                            <div>
                              {v.role ? v.role.label : v.role ? v.role : " "}
                              {""}
                              <div className={classes.dot} />
    
                              {v.department.map((v) => v.label).join()}
    
                              {""}
                              <div className={classes.dot} />
    
                              {v.namelist.map((v) => v.label).join()}
    
                              {""}
                            </div> */}
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.duration+" mins"}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.apServiceType?.label}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.apServiceCategory?.label}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.apServiceCode?.label}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.resourcetype?.label}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.resourcerole?.label}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {" "}
                              {v.resourcedepartment &&
                              v.resourcedepartment.length > 0
                                ? v.resourcedepartment
                                    .map((dep) => dep.label)
                                    .join(", ")
                                : null}
                            </Grid>
                            <Grid style={{ marginLeft: "10px" }}>
                              {v.resourcename && v.resourcename.length > 0
                                ? v.resourcename
                                    .map((name) => name.label)
                                    .join(", ")
                                : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <IconButton
                            aria-label="EditIcon"
                            className={classes.margin}
                            size="small"
                          >
                            <EditIcon
                              onClick={(e) => {
                                handleClick(e);
                                handleEditvalue(v, i);
                              }}
                              style={{ color: "#0071F2", fontSize: 18 }}
                            />
                          </IconButton>
                          <IconButton
                            aria-label="DeleteIcon"
                            className={classes.margin}
                            size="small"
                            onClick={() => deleteResources(i)}
                          >
                            <DeleteIcon
                              style={{ color: "#FF4D4A", fontSize: 18 }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Card>
          </Card>
          <Popover
            open={open}
            id={id}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <div style={{ padding: 18, width: 1200 }}>
              <Grid container direction="row">
                <Grid item lg={11} md={11} xl={11}>
                  <Typography
                    style={{
                      color: "#000000",
                      fontSize: 14,
                      marginBottom: 8,
                      marginTop: 0,
                      fontWeight: "bold",
                    }}
                  >
                    Add Appointment Duration
                  </Typography>
                </Grid>
                <Grid item lg={1} md={1} xl={1} xs={1}>
                  <CloseIcon
                    style={{
                      marginTop: "-18px",
                      cursor: "pointer",
                      marginLeft: "80px",
                    }}
                    onClick={handleClose}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} lg={12}>
                <Grid
                   item lg={4} md={4}
                >
                    <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Service Group/Type{" "}
                    {/* <span style={{ color: "red" }}>
                        *
                    </span> */}
                    </Typography>

                    <SelectBox
                    parent_id={"serviceDefinition-select"}
                    // multi={true}
                    value={resourse?.apServiceType ?? []}
                    // onchange={(e, value) => onClick("servicetype", value)}
                    onchange={(e, v) =>
                      handleChangelist("apServiceType",v)
                    }
                    list={
                      resourceAndServiceDropDown?.serviceTypeList ?? []
                    }
                    
                    // error={error && !finalValue?.servicetype?.length}
                    />
                </Grid>
                <Grid
                    item lg={4} md={4}
                >
                    <Typography
                   style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Service Category
                    {/* <span
                        id={`${parent_id}-service category-star-span`}
                        style={{ color: "red" }}
                    >
                        *
                    </span> */}
                    </Typography>

                    <SelectBox
                    parent_id={"serviceDefinition-select"}
                    // multi={true}
                    value={resourse?.apServiceCategory ?? {}}
                    // onchange={(e, value) => onClick("servicecategory", value)}
                    onchange={(e, v) =>
                      handleChangelist("apServiceCategory",v)
                    }
                    list={
                      resourceAndServiceDropDown?.serviceCategoryList ?? []
                    }
                    
                    // error={error && !finalValue?.servicecategory?.length}
                    />
                </Grid>
                <Grid
                    item lg={4} md={4}
                >
                    <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Service Code
                    {/* <span
                        id={`${parent_id}-Service Code-star-span`}
                        style={{ color: "red" }}
                    >
                        *
                    </span> */}
                    </Typography>
                    <SelectBox
                    parent_id={"serviceDefinition-select"}
                    // multi={true}
                    disabled={false}
                    value={resourse?.apServiceCode ?? []}
                    // onchange={(e, value) => onClick("serviccode", value)}
                    onchange={(e, v) =>
                      handleChangelist("apServiceCode",v)
                    }
                    list={
                      resourceAndServiceDropDown?.apServiceCodeList ?? []
                    }
                    // error={error && !finalValue?.serviccode?.length}
                    />
                </Grid>
              </Grid>
              <Grid container spacing={2} lg={12}>
                <Grid item lg={4} md={4}>
                  <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                  >
                    Resource Types
                    {/* <span className={classes.req_red}>*</span> */}
                  </Typography>
                  <SelectBox
                    parent_id={"resourceDefinition-select"}
                    //disabled={Boolean(editData)}
                    list={resourceAndServiceDropDown?.resourceTypeList ?? []}
                    // list={[]}
                    value={resourse?.resourcetype ?? []}
                    // onchange={(e, v) =>
                    //   handlechange(
                    //     "ResourceTypeList",
                    //     v,
                    //     "PRACTROLE",
                    //     "DEVICEROLE",
                    //     "LOCATIONROLE"
                    //   )
                    // }
                    onchange={(e, v) => {
                      handleChangelist("resourcetype", v);
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4}>
                  <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                  >
                    Resource Role
                    {/* <span className={classes.req_red}>*</span> */}
                  </Typography>
                  <SelectBox
                    parent_id={"resourceDefinition-select"}
                    //disabled={Boolean(editData)}
                    list={resourceAndServiceDropDown?.resourceRoleList ?? []}
                    // list={[]}
                    value={resourse?.resourcerole ?? []}
                    onchange={(e, v) => {
                      handleChangelist("resourcerole", v);
                    }}
                  />
                </Grid>
                <Grid item lg={4}>
                  <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                  >
                    Resource Department
                  </Typography>
                  <SelectBox
                    parent_id={"resourceDefinition-select"}
                    //disabled={Boolean(editData)}
                    multi={true}
                    //disabled={Boolean(editData)}
                    list={resourceAndServiceDropDown?.resourceDepList ?? []}
                    // list={[]}
                    value={resourse?.resourcedepartment ?? []}
                    onchange={(e, v) => {
                      handleChangelist("resourcedepartment", v);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} lg={12}>
                <Grid item lg={4}>
                  <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                  >
                    Resource Name
                  </Typography>
                  <SelectBox
                    parent_id={"resourceDefinition-select"}
                    //disabled={Boolean(editData)}
                    multi={true}
                    //disabled={Boolean(editData)}
                    list={resourceAndServiceDropDown?.resourceNameList ?? []}
                    // list={[]}
                    value={resourse?.resourcename ?? []}
    
                    onchange={(e, v) => {
                      handleChangelist("resourcename", v);
                    }}
                  />
                </Grid>
                <Grid
                    item
                    md={2}
                    lg={2}
                    xs={2}
                    xl={2}
                    className={classes.formParent}
                >
                    <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    variant="body1"
                    >
                    Duration in Mins{" "}
                    <span id={`Code-star-span`} style={{ color: "red" }}>
                        *
                    </span>
                    </Typography>
                    <TextField
                    // disabled={Boolean(editData)}
                    fullWidth
                    variant="outlined"
                    size="small"
                    // onChange={(e) => handlechange("code", e.target.value)}
                    onChange={(e) => {
                        if (/^[0-9\s@\/]*$/.test(e.target.value)) {
                            handleChangelist("duration", Number(e.target.value ?? ""))
                        }
                      }
                    }
                    value={resourse?.duration ?? ""}
                    labelStyle={labelStyle}
                    style={
                        error && !resourse?.duration
                        ? errorBorderStyle
                        : borderStyle
                    }
                    // error={props.error?.title}
                    />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                float: "right",
                paddingBottom: "20px",
              }}
            >
              <Button
                variant="outlined"
                size="medium"
                style={{ marginRight: "20px" }}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                // color="primary"
                style={{
                  marginRight: "34px",
                  backgroundColor: "#0071F2",
                  color: "white",
                }}
                onClick={() => handlesubmit()}
              >
                {editshow ? "update" : "save"}
              </Button>
            </div>
            {/* <div
              style={{
                width: "850px",
                height: "40px",
              }}
            ></div> */}
          </Popover>
        </>
      );
}

export default AppointmentDurationCard