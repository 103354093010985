/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2021-02-03
 * @desc Different routes and their corresponding component are defined here.
 */

import React from "react";
import Insert from "../insert";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import routes from "./routes";
import {
  NotFound,
  Repository,
  SignIn,
  AvailabilityManagement,
  Administration,
  PractitionerMaster,
  OrganizationConfig,
} from "./../screens";
import Addpractitioner from "../components/practitionermaster/addPractotioner/index";
import AddLocation from "../screens/administration/addLocation/index";
// import TimeTable from "../components/availabilitymanagement/timetable";
import CreateTimeTable from "../components/availabilitymanagement/createNewTimeTable";
import CreateAppoointment from "../components/availabilitymanagement/createNewAppointment";
import AddAppointmentType from "../components/availabilitymanagement/addAppointmentTypes";
import ConsultParent from "../screens/consultMasters/index";
import FrequencyMasterParent from "../screens/frequencyMaster/index";
import DrugMasterParent from "../screens/drugMaster/index";
import Organization from "../screens/organizations/index";
import AddHealthCareService from "../components/organizationConfig/addHealthCareService";
import AddSpeciality from "../components/organizationConfig/addSpeciality";
import AddNewPerson from "../components/repositorymaster/addNewPerson";
import PrivateRoute from "./privateRouter";
import parameterSetup from "../screens/parameterSetup";
import { FrequencyAddEditComponent } from "../screens/frequencyMaster/components/frequency";
import { DrugsAddEditComponent } from "../screens/drugMaster/components/drug";
import { Alertrestriction } from "../screens/drugMaster/components/drug";
import General from "../screens/availabilityManagement/general";
import navbar from "../screens/availabilityManagement/appNavBar";
import Service from "../screens/availabilityManagement/createServiceDefinition";
import RefRightsList from "../components/eReferral/ReferralRights/refRightsList";
import RefType from "../components/eReferral/referralType";
import AlertNotification from "../components/alertnotification/alertnotification";

import { Facts } from "../components/ruleBuilder/facts";
import EventConfig from "../components/atpManagement/eventconfiguration/eventConfigList";
import ApiHub from "../components/atpManagement/apiHub/apiHub"

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* form component list */}
        {/* <Redirect exact from="/" to={routes.availabilitymanagement} /> */}
        <Route exact path="/" component={SignIn} />
        <Route exact path={routes.signIn} component={SignIn} />
        {/* <Route exact path={routes.availabilitymanagement} component={Insert} />  */}
        {/* <Route exact path={routes.timetable} component={TimeTable} /> */}
        {/*ROUTES FOR organization Config */}
        <PrivateRoute
          exact
          path={routes.organizationConfig}
          component={OrganizationConfig}
        />
        <PrivateRoute
          exact
          path={routes.parameterSetup}
          component={parameterSetup}
        />
        <PrivateRoute exact path={routes.consult} component={ConsultParent} />
        {/* FREQUENCY MASTER ROUTES */}
        <PrivateRoute
          exact
          path={routes.frequency}
          component={FrequencyMasterParent}
        />
        <PrivateRoute
          exact
          path={routes.addFrequency}
          component={FrequencyAddEditComponent}
        />
        <PrivateRoute
          exact
          path={routes.editFrequency}
          component={FrequencyAddEditComponent}
        />

        {/* Drug MASTER ROUTES */}
        <PrivateRoute
          exact
          path={routes.DrugMasters}
          component={DrugMasterParent}
        />
        <PrivateRoute
          exact
          path={routes.addNewDrug}
          component={DrugsAddEditComponent}
        />
        <PrivateRoute
          exact
          path={routes.editNewDrug}
          component={DrugsAddEditComponent}
        />
        <PrivateRoute
          exact
          path={routes.alertRestriction}
          component={Alertrestriction}
        />

        <PrivateRoute
          exact
          path={routes.addpractitioner}
          component={Addpractitioner}
        />
        <PrivateRoute exact path={routes.addperson} component={AddNewPerson} />
        <PrivateRoute
          exact
          path={routes.addhealthcareservice}
          component={AddHealthCareService}
        />

        <PrivateRoute
          exact
          path={routes.addspeciality}
          component={AddSpeciality}
        />

        <PrivateRoute
          exact
          path={routes.addlocationMater}
          component={AddLocation}
        />
        <PrivateRoute
          exact
          path={routes.createappointment}
          component={CreateAppoointment}
        />
        <PrivateRoute
          exact
          path={routes.createTimeTable}
          component={CreateTimeTable}
        />
        <PrivateRoute
          exact
          path={routes.administration}
          component={Administration}
        />
        <PrivateRoute
          exact
          path={routes.availabilitymanagement}
          component={AvailabilityManagement}
        />
        {/* Routes for Repository */}
        <PrivateRoute exact path={routes.repository} component={Repository} />

        {/*ROUTES FOR Organization MASTER */}
        <PrivateRoute
          exact
          path={routes.organization}
          component={Organization}
        />

        {/*ROUTES FOR PRACTITIONER MASTER */}
        <PrivateRoute
          exact
          path={routes.practitionermaster}
          component={PractitionerMaster}
        />
        <PrivateRoute
          exact
          path={routes.referalrights}
          component={RefRightsList}
        />
        <PrivateRoute
          exact
          path={routes.referraltype}
          component={RefType}
        />

        <PrivateRoute
          exact
          path={routes.alertnotification}
          component={AlertNotification}
        />
        <PrivateRoute
          exact
          path={routes.eventconfiguration}
          component={EventConfig}
        />

        {/*ROUTES FOR ADD APPOINMENT TYPES MASTER */}
        <PrivateRoute
          exact
          path={routes.addAppointmentType}
          component={AddAppointmentType}
        />
        {/*Rule Builder - Facts Definition */}
        <PrivateRoute
          exact
          path={routes.factsDefinition}
          component={Facts}
        />

       {/*API HUB Connector */}
        <PrivateRoute
          exact
          path={routes.apiHub}
          component={ApiHub}
        />


        {/* For unknow/non-defined path */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
