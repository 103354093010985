import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Paper,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import deleteicon from "../../../../assets/icons8-trash.svg";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { CommonTable } from "../../../../components";
import { constructAppointmentTable } from "./constructJson";
import { AxiosCall } from "../../../../utils";
import Config from "../../../../config";
import { disable } from "workbox-navigation-preload";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    margin: 15,
  },
  align: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  header: {
    color: "#101010",
    fontSize: "14px",
    paddingRight: "4px",
    fontWeight: 600,
  },
  subHeader: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginRight: "4px",
  },
  dot: {
    backgroundColor: "#6F6F6F",
    borderRadius: "50%",
    padding: "2px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    width: "38px",
  },
  monthcheckbox: {
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
      fontFamily: "poppin !important",
    },
  },
  marginleft10: {
    marginLeft: "10px",
    fontSize: "12px",
    fontFamily: "poppin",
  },
  perioSelection: {
    paddingLeft: 8,
    borderRadius: "8px",
  },
  divider: {
    marginTop: "9px",
    borderBottom: "1px dashed #e9e9e9",
    background: "none",
  },
  atitleData: {
    display: "grid",
    gridTemplateColumns: "160px 1fr",
    paddingBottom: "8px",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
}));

const LoadPeriod = (props) => {
  const classes = useStyles();
  const { name, options, color, stateData = {},dataView } = props;
  return (
    <div style={{ display: "flex", marginTop: "6px" }}>
      <div style={{ display: "flex" }}>
        <p className={classes.grayfont}>{name}</p>
        <div
          style={{
            height: "25px",
            width: "3px",
            backgroundColor: color,
            marginLeft: "20px",
            marginTop: "8px",
          }}
        ></div>
      </div>
      <div className={classes.monthcheckbox}>
        <FormGroup row>
          {options?.map((val) => {
            return (
              <FormControlLabel
                className={classes.marginleft10}
                control={ dataView?
                  <Checkbox
                    checked={true}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name={val.label}
                    disabled={true}
                    color="primary"
                  /> :
                  <Checkbox
                    checked={stateData?.[val.value] ?? false}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name={val.label}
                    disabled={true}
                    color="primary"
                  />
                }
                label={val.label}
              />
            );
          })}
        </FormGroup>
      </div>
    </div>
  );
};

const AvailabilityDetailsList = (props) => {
  const classes = useStyles();
  const [actualMonth, setActualMonth] = useState([]);
  const [actualDays, setActualDays] = useState([]);
  const [actualWeek, setActualWeek] = useState([]);
  const [actualHoliday, setActualHoliday] = useState([]);
  const [expandDetails, setExpandDetails] = useState(false);
  const [period, setPeriod] = React.useState({
    month: month,
    Week: Week,
    Days: Days,
    Holidays: Holidays,
    isfirst: false,
  });
  const [editAppointmentTable, setEditAppointmentTable] = useState([]);


  useEffect(() => {
    // setPeriod({
    //   ...period,
    //   Holidays: props?.stateData.holidays_time_table.data,
    // });
    setPeriod({...period,Holidays:props.AllHoliday})
    if(props.viewData){
      setPeriod({...period,Holidays:props.val.holidays})
      setExpandDetails(true);
    }
    
    const filterKeys = Object.keys(props?.val);
    const editKeys = findTheKeyss(filterKeys, props?.val);
    const returnMonths = month.filter((val) =>
      props?.editData
        ? editKeys.includes(val?.value)
        : filterKeys.includes(val?.value)
    );
    const reuturnDays = Days.filter((val) =>
      props?.editData
        ? editKeys.includes(val?.value)
        : filterKeys.includes(val?.value)
    );
    const returnWeek = Week.filter((val) =>
      props?.editData
        ? editKeys.includes(val?.value)
        : filterKeys.includes(val?.value)
    );
    const returnHoliday=props.AllHoliday.filter((val) =>
      props?.editData
        ? editKeys.includes(val?.value)
        : filterKeys.includes(val?.value)
    );
    
    setActualWeek(returnWeek);
    setActualMonth(returnMonths);
    setActualDays(reuturnDays);
    setActualHoliday(returnHoliday)
    const appointmentTable = constructAppointmentTable(
      props?.val?.appointmentList
    );
    // console.log(appointmentTable, "appointmentTable");
    setEditAppointmentTable(appointmentTable);
  }, []);

  const findTheKeyss = (filterKeys, totalObject) => {
    let result = [];
    filterKeys.forEach((key) => {
      if (totalObject[key] === true) {
        result.push(key);
      }
    });
    return result;
  };

  const handleExpand = () => {
    setExpandDetails(true);
  };

  const handleLess = () => {
    setExpandDetails(false);
  };

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <div
          className={classes.align}
          style={{ justifyContent: "space-between" }}
        >
          <div className={classes.align}>
            <Typography className={classes.header}>
              ({props?.i < 9 ? "0" + (props?.i + 1) : props?.i + 1})
              AVAILABILITY DETAILS
            </Typography>
            {!expandDetails&& !props.viewData && (
              <>
                <div className={classes.align} style={{ gap: 0 }}>
                  {actualMonth.map((values, i) => (
                    <Typography className={classes.subHeader}>
                      {(i === actualMonth.length - 1 && actualMonth.length > 1
                        ? " & "
                        : "") +
                        values.label +
                        (i === actualMonth.length - 1 ||
                        i === actualMonth.length - 2
                          ? ""
                          : ", ")}
                    </Typography>
                  ))}
                </div>
                <div className={classes.dot}></div>
                <div className={classes.align} style={{ gap: 0 }}>
                  {actualWeek.map((values, i) => (
                    <Typography className={classes.subHeader}>
                      {(i === actualWeek.length - 1 && actualWeek.length > 1
                        ? " & "
                        : "") +
                        values.label +
                        (i === actualWeek.length - 1 ||
                        i === actualWeek.length - 2
                          ? ""
                          : ", ")}
                    </Typography>
                  ))}
                </div>
                <div className={classes.dot}></div>
                <div className={classes.align} style={{ gap: 0 }}>
                  {actualDays.map((values, i) => (
                    <Typography className={classes.subHeader}>
                      {(i === actualDays.length - 1 && actualDays.length > 1
                        ? " & "
                        : "") +
                        values.label +
                        (i === actualDays.length - 1 ||
                        i === actualDays.length - 2
                          ? ""
                          : ", ")}
                    </Typography>
                  ))}
                </div>
                <div className={classes.dot}></div>
                <div className={classes.align} style={{ gap: 0 }}>
                  {actualHoliday.map((values, i) => (
                    <Typography className={classes.subHeader}>
                      {(i === actualHoliday.length - 1 && actualHoliday.length > 1
                        ? " & "
                        : "") +
                        values.label +
                        (i === actualHoliday.length - 1 ||
                        i === actualHoliday.length - 2
                          ? ""
                          : ", ")}
                    </Typography>
                  ))}
                </div>
              </>
            )}
          </div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!props?.editData && (
                <img
                  src={deleteicon}
                  alt="Icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => props?.handleDelete(props?.i)}
                />
              )}
              {expandDetails === false && (
                <ExpandMoreRoundedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleExpand()}
                />
              )}
              {expandDetails === true && (
                <ExpandLessIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLess()}
                />
              )}
            </div>
          </div>
        </div>
        <Collapse in={expandDetails}>
          <Grid className={classes.perioSelection}>
            <LoadPeriod
              stateData={props.val}
              color="#0C78F2"
              name="Month"
              options={period.month}
            />
            <LoadPeriod
              stateData={props.val}
              color="#F5900C"
              name="Week"
              options={period.Week}
            />
            <LoadPeriod
              stateData={props.val}
              color="#F5CE0C"
              name="Days"
              options={period.Days}
            />
            <LoadPeriod
              stateData={props.val}  
              color="#00DDF5"
              name="Holidays"
              options={period.Holidays}
              dataView={props.viewData}
            /> 
          </Grid>
          <Grid className={classes.atitleData}>
            <Typography className={classes.title} variant="h6">
              APPOINTMENT DURATION
            </Typography>
            <Divider className={classes.divider} />
          </Grid>
          <CommonTable
            parent_id={"category-details"}
            rightAlign={["Action"]}
            Header={[
              "DurnType",
              "Day Type",
              "Start Time",
              "End Time",
              "Slot Durn",
              "Max appt/Slot",
              "Max appt/Slab",
              "Max appt allowed",
              "Max overbooking",
              "Max walkins",
              "Max waitlist",
              "mode",
            ]}
            dataList={editAppointmentTable ?? []}
            headerSize={"11px"}
            tableData={[
              { type: ["TEXT"], name: "duration_type" },
              { type: ["TEXT"], name: "day_type" },
              { type: ["TEXT"], name: "start_time" },
              { type: ["TEXT"], name: "end_time" },
              { type: ["TEXT"], name: "slot_duration" },
              { type: ["TEXT"], name: "max_appoinment" },
              { type: ["TEXT"], name: "max_appoinment" },
              { type: ["TEXT"], name: "max_appoinment_allowed" },
              { type: ["TEXT"], name: "max_over_booking" },
              { type: ["TEXT"], name: "max_walking" },
              { type: ["TEXT"], name: "max_wait_list" },
              { type: ["MODE"], name: "consultation_type" },
            ]}
          />
        </Collapse>
      </Paper>
    </React.Fragment>
  );
};

export default AvailabilityDetailsList;

const month = [
  { value: "Month1", label: "Jan", disabled: false, name: "Jan" },
  { value: "Month2", label: "Feb", disabled: false, name: "Feb" },
  { value: "Month3", label: "Mar", disabled: false, name: "Mar" },
  { value: "Month4", label: "Apr", disabled: false, name: "Apr" },
  { value: "Month5", label: "May", disabled: false, name: "May" },
  { value: "Month6", label: "Jun", disabled: false, name: "Jun" },
  { value: "Month7", label: "Jul", disabled: false, name: "Jul" },
  { value: "Month8", label: "Aug", disabled: false, name: "Aug" },
  { value: "Month9", label: "Sep", disabled: false, name: "Sep" },
  { value: "Month10", label: "Oct", disabled: false, name: "Oct" },
  { value: "Month11", label: "Nov", disabled: false, name: "Nov" },
  { value: "Month12", label: "Dec", disabled: false, name: "Dec" },
];
const Week = [
  { value: "Week1", label: "Week 1", disabled: false, name: 1 },
  { value: "Week2", label: "Week 2", disabled: false, name: 2 },
  { value: "Week3", label: "Week 3", disabled: false, name: 3 },
  { value: "Week4", label: "Week 4", disabled: false, name: 4 },
  { value: "Week5", label: "Week 5", disabled: false, name: 5 },
];
const Days = [
  { value: "Day1", label: "Sun", disabled: false, name: "Sunday" },
  { value: "Day2", label: "Mon", disabled: false, name: "Monday" },
  { value: "Day3", label: "Tue", disabled: false, name: "Tuesday" },
  { value: "Day4", label: "Wed", disabled: false, name: "Wednesday" },
  { value: "Day5", label: "Thu", disabled: false, name: "Thursday" },
  { value: "Day6", label: "Fri", disabled: false, name: "Friday" },
  { value: "Day7", label: "Sat", disabled: false, name: "Saturday" },
];
const Holidays = [];
