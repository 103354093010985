import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import { actions } from "primary_care_admin_binder";
import IconButton from "@material-ui/core/IconButton";
import { withAllContexts, withHoverDrawer } from "../../HOCs";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import CreateAppointmentTypes from "./createAppointmentTypes/CreateAppointmentTypes";
import CreatePatientCategory from "./createAppointmentTypes/CreatePatientCategory";
import ResourceDefinitionCard from "./createAppointmentTypes/resourceDefinition";
import SpecialAppoitmentInstructions from "./createAppointmentTypes/specialAppoitmentInstructions";
import StatusBar from "./createAppointmentTypes/StatusBar";
import { AlertContext } from "../../contexts";
import GeneralAppointmentCard from "./generalappointmentcard";
import Check from "../../screens/Check";
import GeneralAppoitmentInstructions from "./createAppointmentTypes/GeneralAppointmentIns";
import CreateServiceDefinition from "./createAppointmentTypes/serviceDefinition";
import { GenerateFormVersion2 } from "../common";
import CreateUserRole from "./createAppointmentTypes/CreateUserRole";
import BookingSource from "./createAppointmentTypes/BookingSource";
import { AlertProps, AxiosCall } from "../../utils";
import AppointmentDurationCard from "./createAppointmentTypes/appointmentDurarion";
import PatientInclusionCriteria from "./createAppointmentTypes/patientInclCriteria";
import TreeComponent from "../common/treeComp";
import Config from "../../config";
import CreateBookingType from "./createAppointmentTypes/AppTypeAdditionalInfo";
import AdditionalInfoTab from "./createAppointmentTypes/AppAdditionInfoTab";

const useStyles = makeStyles((theme) => ({
  modalStructure: {
    padding: "18px",
    width: "86%",
  },
  facilityCancel: {
    background: "#ffe9e3",
    padding: 6,
    borderRadius: 7,
    color: "#ec6a49",
    textTransform: "none",
    fontSize: 16,
    fontFamily: "pc_semibold",
    "&:hover": {
      background: "#ffe9e3",
    },
  },
  normalBtn: {
    background: "#fff",
    padding: "0px 15px",
    borderRadius: 7,
    color: "#000",
    textTransform: "none",
    fontSize: 16,
    fontFamily: "pc_semibold",
  },
  saveBtn: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 7,
    textTransform: "none",
    fontSize: 16,
    fontFamily: "pc_semibold",
    background: "#0071f2",
    color: "#fff",
    "&:hover": {
      background: "#0071f2",
    },
  },
}));
const data = [
  {
    id: "1",
    entity: "AINQA - INDIA",
    reason: "",
    effectiveFrom: "",
    effectiveTo: "",
    status: "true",
    children: [
      {
        id: "1.1",
        entity: "Apollo Multi-Speciality, Tamilnadu",
        reason: "",
        effectiveFrom: "",
        effectiveTo: "",
        status: "true",
        children: [
          {
            id: "1.1.1",
            entity: "Apollo Multi-Speciality, Madurai",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
          {
            id: "5",
            entity: "Apollo Multi-Speciality, Chennai",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
          {
            id: "6",
            entity: "Apollo Multi-Speciality, Trichy",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
        ],
      },
      {
        id: "1.2",
        entity: "Apollo Multi-Speciality, Kerala",
        reason: "",
        effectiveFrom: "",
        effectiveTo: "",
        status: "true",
        children: [
          {
            id: "1.2.1",
            entity: "Apollo Multi-Speciality, Kollam",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
          {
            id: "9",
            entity: "Apollo Multi-Speciality, Idukki",
            reason: "",
            effectiveFrom: "",
            effectiveTo: "",
            status: "true",
          },
        ],
      },
    ],
  },
];

const AddAppointmentType = (props) => {
  // const [state, setState] = useState({});
  const alert = useContext(AlertContext);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [treeListData, setTreeListData] = React.useState([]);
  const [checkedData, setcheckedData] = React.useState([]);
  const [loading, setLoading] = React.useState();
  const Controller = location.IsEdit;
  const Ediitvalue = location?.state?.edit_data;
  const [error, setError] = React.useState(false);
  const [priorityEdit,setpriorityEdit]=React.useState(false);
  const [finalValue, setFinalValue] = React.useState({
    id: 0,
    code: "",
    shortdesc: "",
    longdesc: "",
    bookingsource: "",
    patientcategory: [],
    servicetype: "",
    servicecategory: "",
    serviccode: "",
    userroles: [],
    observationdetails :[],
    bookingtype:"",
    servicedefinition:[],
    specialapptinstructions: [],
    resourcedefinition: [],
    generalappinstruction: [],
    status: true,
    statusbyentity: [],
    ApptTypeCode: "",
    userRoles: "",
    workQueue:"",
    apServiceType: [],
    apServiceCategory: [],
    apServiceCode: [],
    duration:null,
    appointmentDuration: [],
    patientInclCriteria:"",
    priorityDefinition:[]
  });
  const [valueshow, setvalueshow] = useState([]);
  const [bookingType,setBookingType]=useState({
    id:"",
    label:""
  });
  const [observation, setObservation] = useState({
    vitalcode: "",
    mandatory: false,
    sequence: ""
  });
  const [showValuepddata, setshowValuepddata] = useState([]);
  const EntityMasters = useSelector(
    (state) => state?.holidaySlice?.entity_master?.data
  );
  const backfun = () => {
    setFinalValue({
      id: 0,
      code: "",
      shortdesc: "",
      longdesc: "",
      bookingsource: "",
      observationdetails:[],
      patientcategory: [],
      servicetype: [],
      servicecategory: [],
      serviccode: [],
      userroles: [],
      specialapptinstructions: [],
      resourcedefinition: [],
      generalappinstruction: [],
      status: true,
      statusbyentity: [],
      ApptTypeCode: "",
      priorityDefinition:[]
    });
    history.push({
      pathname: routes.availabilitymanagement,
      state: { index: 2 },
    });
  };
  const [steperSave, setSteperSave] = React.useState(false);
  const [priorityDefinitiondata, setPriorityDefinitiondata] = React.useState([]);

  const TableReadData = useSelector(
    (state) =>
      state.resourceByAppointmentTypesSlice.res_appointment_single_read_qdm.data
  );
  const saveFuntion = async () => {
    if (
      !finalValue?.code ||
      !finalValue?.shortdesc ||
      !finalValue?.longdesc ||
      !finalValue?.bookingsource?.label ||
      !finalValue?.userroles?.length > 0 ||
      !finalValue?.workQueue?.label
    ) {
      setError(true);
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });

      return;
    }else if ((finalValue?.bookingtype?.label).toLowerCase() == "multiple" && !finalValue?.observationdetails?.length > 0 ){
      setError(true);
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the Additional Info!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }else if (!finalValue?.servicedefinition?.length > 0){
      setError(true);
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the Service Definition!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    } else if (!finalValue?.resourcedefinition?.length > 0){
      setError(true);
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the Resource Definition!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    } else if (!finalValue?.appointmentDuration?.length > 0){
      setError(true);
      alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the Appointment Duration!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
      return;
    }

    setLoading(true);
    // let setPatientvalue = [];
    // if (finalValue?.patientcategory && finalValue?.patientcategory.length > 0) {
    //   setPatientvalue = finalValue.patientcategory.map((item) => item?._id);
    // }
    // let setServiceType = [];
    // if (finalValue?.servicetype && finalValue?.servicetype.length > 0) {
    //   setServiceType = finalValue.servicetype.map((item) => item?._id);
    // }
    // let setServiceCategory = [];
    // if (finalValue?.servicecategory && finalValue?.servicecategory.length > 0) {
    //   setServiceCategory = finalValue.servicecategory.map((item) => item?._id);
    // }
    // let setServiceCode = [];
    // if (finalValue?.serviccode && finalValue?.serviccode.length > 0) {
    //   setServiceCode = finalValue.serviccode.map((item) => item?._id);
    // }
    let setUserRoles = [];
    if (finalValue?.userroles && finalValue?.userroles.length > 0) {
      setUserRoles = finalValue.userroles.map((item) => item?._id);
    }
    let setresourceDefinition = [];
    if (
      finalValue?.resourcedefinition &&
      finalValue.resourcedefinition.length > 0
    ) {
      setresourceDefinition = finalValue.resourcedefinition.map((resource) => ({
        resourcetype: resource?.resourcetype?._id,
        resourcerole: resource?.resourcerole?._id,
        resourcedepartment:
          resource?.resourcedepartment?.map((department) => department?._id) ||
          [],
        resourcename: resource?.resourcename?.map((name) => name?._id) || [],
      }));
    }

    let setobservationdetaildata = [];
    if (
      finalValue?.observationdetails&&
      finalValue.observationdetails.length > 0
    ) {
      setobservationdetaildata = finalValue.observationdetails.map((val) => ({
        vitalcode: val?.vitalcode?._id,
        mandatory: val?.mandatory,
        sequence: val?.sequence,
      }));
    }

    let setserviceDefinition = [];
    if (
      finalValue?.servicedefinition &&
      finalValue.servicedefinition.length > 0
    ) {
      setserviceDefinition = finalValue.servicedefinition.map((val) => ({
        servicegrouptype: val?.servicetype?._id,
        servicecategory: val?.servicecategory?._id,
        servicecode: val?.servicecode?._id,
      }));
    }

    let setappointmentDuration = [];
    if (
      finalValue?.appointmentDuration &&
      finalValue.appointmentDuration.length > 0
    ) {
      setappointmentDuration = finalValue.appointmentDuration.map((val) => ({
        servicegrouptype: val?.apServiceType?._id,
        servicecategory: val?.apServiceCategory?._id,
        servicecode: val?.apServiceCode?._id,
        resourcetype: val?.resourcetype?._id,
        resourcerole: val?.resourcerole?._id,
        resourcedepartment:
          val?.resourcedepartment?.map((department) => department?._id) ||
          [],
        resourcename: val?.resourcename?.map((name) => name?._id) || [],
        durationinmin: val?.duration,
      }));
    }

    let setgeneralInstruction = [];
    if (
      finalValue?.generalappinstruction &&
      finalValue.generalappinstruction.length > 0
    ) {
      setgeneralInstruction = finalValue.generalappinstruction.map(
        (resource) => ({
          code: resource?.code?._id || resource?._id,
          description: resource?.description || resource?.display,
        })
      );
    }
    let setSpecialappinstruction = [];
    if (
      finalValue?.specialapptinstructions &&
      finalValue.specialapptinstructions.length > 0
    ) {
      setSpecialappinstruction = finalValue.specialapptinstructions.map(
        (resource) => ({
          appcode: resource?.appcode?._id,
          appinstructioncriteria: resource?.appinstructioncriteria?._id,
          appinstructions: resource?.appinstructions,
        })
      );
    }

    const valueSet = {
      id: 0,
      code: finalValue?.code,
      shortdesc: finalValue?.shortdesc,
      longdesc: finalValue?.longdesc,
      // bookingsource: finalValue?.bookingsource?._id,
      // patientcategory: setPatientvalue || [],
      servicedefinition: setserviceDefinition,
      appointmentduration: setappointmentDuration,
      bookingsource: finalValue?.bookingsource?._id,
      userroles: setUserRoles,
      workqueue: finalValue?.workQueue?._id,
      // bookingsourcedetail: [
      //   {
      //     bookingsource: finalValue?.bookingsource?._id,
      //     userroles: setUserRoles,
      //     workqueue: finalValue?.workQueue?._id
      //   }
      // ],
      patientInclusioncriteria: [finalValue?.patientInclCriteria?._id],
      // userroles: setUserRoles,
      specialapptinstructions: setSpecialappinstruction || [],
      resourcedefinition: setresourceDefinition || [],
      generalappinstruction: setgeneralInstruction || [],
      status: true,
      statusbyentity: checkedData ?? [],
      observationdetails:setobservationdetaildata,
      bookingtype:finalValue?.bookingtype?._id,
      prioritydefinition:finalValue?.priorityDefinition
      // ApptTypeCode: "",
    };
    if (Ediitvalue) {
      const transformedData = valueSet?.prioritydefinition?.map(item => ({
        obscode: item.obscode._id,
        obsvalue: item.obsvalue.map(obs => obs._id),
        priority: item.priority._id
    }));
    valueSet.prioritydefinition = transformedData;
      let resp = await dispatch(
        actions.RES_UPDATE_APPOINTMENT({
          final: valueSet,
          data: TableReadData,
        })
      );
      if(resp){
        props.alert.setSnack("success", "Update Sucessfully");
        backfun();
        setFinalValue({
          id: 0,
          code: "",
          shortdesc: "",
          longdesc: "",
          bookingsource: "",
          patientcategory: [],
          servicetype: [],
          servicecategory: [],
          serviccode: [],
          userroles: "",
          specialapptinstructions: [],
          resourcedefinition: [],
          generalappinstruction: [],
          status: true,
          statusbyentity: [],
          ApptTypeCode: "",
        });
      } else {
        props.alert.setSnack("error", "Failed to Update");
      }
    } else {
      let resp = await dispatch(actions.RES_UPSERT_APPOINTMENT(valueSet));
      if(resp){
        props.alert.setSnack("success", "Added Sucessfully");
        backfun();
        setFinalValue({
          id: 0,
          code: "",
          shortdesc: "",
          longdesc: "",
          bookingsource: "",
          patientcategory: [],
          servicetype: [],
          servicecategory: [],
          serviccode: [],
          userroles: "",
          specialapptinstructions: [],
          resourcedefinition: [],
          generalappinstruction: [],
          status: true,
          statusbyentity: [],
          ApptTypeCode: "",
        });
      } else {
        props.alert.setSnack("error", "Failed to Save");
      }
    }
    setLoading(false);
  };
  const [state, setState] = React.useState({
    code: "11",
    longdesc: "test",
    shortdesc: "tedtt",
    patientcategory: [],
    // Instructioncode: "",
    // Description: "",
    resourcedefinition: [],
    ResourceRole: [],
    ResourceDepartment: [],
    ResourceName: [],
    Resourse: [],
    SpecialAppointments: [],
    curreteditresourceindex: "",
    CriteriaDoc: [],
  });
  const PatientCategory = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_patient_category_list_doc_qdm
        ?.data
  );
  const ResourceDefinitionDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_type_list_doc_qdm
        ?.data
  );
  const ResourceRoleDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_role_list_doc_qdm
        ?.data
  );
  const ResourceDepartmentDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice
        ?.res_resource_department_list_doc_qdm?.data
  );
  const ResourceNametDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice?.res_resource_name_list_doc_qdm
        ?.data
  );
  const ResourceInstructionCodeDrop = useSelector(
    (state) =>
      state?.resourceByAppointmentTypesSlice
        ?.res_special_instruction_list_doc_qdm?.data
  );
  const appointmantAdd = useSelector(
    (state) => state?.resourceByAppointmentTypesSlice?.insert_appointment_add
  );

  const Criteria = useSelector(
    (state) =>
      state.resourceByAppointmentTypesSlice?.res_criteria_instruction_qdm?.data
  );

  const getCheckedIds = (data) => {
    return data?.flatMap(item => {
        const ids = item.checked ? [item._id] : [];
        const orgIds = item.Organization ? getCheckedIds(item.Organization) : [];
        const facilityIds = item.facility ? getCheckedIds(item.facility) : [];
        return ids.concat(orgIds, facilityIds);
    });
  }

  // useEffect(() => {
  //   if (Controller) {
  //     setSteperSave(true);
  //   } else {
  //     setSteperSave(false);
  //   }
  // }, [Controller]);

  // React.useEffect(() => {
  //   const arr = [];
  //   SingleReadData?.result?.[0]?.resourcedefinition?.map((item) => {
  //     arr.push({
  //       type: {
  //         value: item?.resourcetype?._id,
  //         label: item?.resourcetype?.display,
  //       },
  //       role: {
  //         value: item?.resourcerole._id,
  //         label: item?.resourcerole.display,
  //         title: "PRACTROLE",
  //       },
  //       department: [
  //         {
  //           value: "CodingMaster/10177",
  //           label: "Radiology Department",
  //         },
  //       ],
  //       namelist: [
  //         {
  //           label: "sum",
  //           value: "cro",
  //         },
  //       ],
  //     });
  //   });
  //   const arr1 = [];
  //   SingleReadData?.result?.[0]?.patientcategory?.map((item) => {
  //     arr1.push({
  //       label: item?.display,
  //       value: item?._id,
  //     });
  //   });
  //   if (Controller) {
  //     setFinalValue({
  //       // id: "",
  //       code: SingleReadData?.result?.[0]?.code,
  //       shortdesc: SingleReadData?.result?.[0]?.shortdesc,
  //       longdesc: SingleReadData?.result?.[0]?.longdesc,
  //       // servicetype: [],
  //       // specialapptinstructions: [],
  //       // resourcedefinition: arr,
  //       // generalappinstruction: [],
  //       // status: true,
  //       // SourceSystemID: "string",
  //       // SourceSystemRefId: "string",
  //       // servicecategory: [],
  //       // serviccode: [],
  //       // patientcategory: SingleReadData?.result?.[0]?.patientcategory,
  //       // statusbyentity: [],
  //     });
  //     setState({
  //       ...state,
  //       Resourse: arr,
  //       Patientcategory: arr1,
  //     });
  //   }
  // }, [SingleReadData]);
  React.useEffect(()=>{
    const getData = async () => {
      let payload = {}
      // if(TableReadData?.[0]?._id){
      //   payload = {
      //     db_name: process.env.REACT_APP_DATABASE_NAME,
      //     filter: {
      //       _id: TableReadData?.[0]?._id
      //     },
      //     queryid: Config.getOrgTreeReadQueryId
      //   }
      // } else {
        payload = {
          db_name: process.env.REACT_APP_DATABASE_NAME,
          queryid: Config.getOrgTreeQueryId
        }
      // }
     
      let Resp = await AxiosCall(payload,process.env.REACT_APP_READ_QDM_QUERY)
      if(Resp){
        setTreeListData(Resp);
        // if(TableReadData?.[0]?._id){
        //   let checkedIdList = getCheckedIds(Resp)
        //   setcheckedData(checkedIdList)
        // }
      }
    }
    getData();
  },[TableReadData?.[0]?._id])
  const patientcategoryData = {};
  const handleshowPopup = () => {
    setState({
      ...state,
      open: true,
      isAdd: true,
    });
  };
  // React.useEffect(() => {
  //   // let main = [];
  //   // let arr = [];
  //   // main.push({
  //   //   entity: "AINQA - INDIA",
  //   //   id: "1",
  //   //   reason: "",
  //   //   // status: "true",
  //   //   children: arr,
  //   // });
  //   // EntityMasters?.map((item) => {
  //   //   let subArr = [];
  //   //   if (item?.Organization?.length) {
  //   //     item?.Organization?.map((value) => {
  //   //       return subArr.push({
  //   //         entity: value?.name,
  //   //         id: value?._id,
  //   //         // effectiveFrom: "test",
  //   //         // effectiveTo: "test1",
  //   //         reason: "",
  //   //         check: false,
  //   //         // status: value?.activestatus,
  //   //         _key: value?._key,
  //   //       });
  //   //     });
  //   //   }
  //   //   return arr.push({
  //   //     entity: item?.name,
  //   //     id: item?._id,
  //   //     // effectiveFrom: "test",
  //   //     // effectiveTo: "test1",
  //   //     check: false,
  //   //     // status: item?.activestatus,
  //   //     children: subArr,
  //   //     _key: item?._key,
  //   //   });
  //   // });
  //   // setTreeListData(main);
  //   const constructFacility = (data) => {
  //     // debugger
  //     let resultData__ = [];
  //     if (Array.isArray(data) && data?.length > 0) {
  //       data.map((val, i) => {
  //         resultData__.push({
  //           OrgType: "Facility",
  //           id: val?._id ?? "",
  //           effectiveFrom: "20-06-2021",
  //           effectiveTo: "20-12-2021",
  //           entity: val?.name ?? "",
  //           reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
  //           status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
  //         });
  //       });
  //     }
  //     return resultData__;
  //   };
  //   const constructOrganization = (data) => {
  //     // debugger
  //     let resultData_ = [];
  //     if (Array.isArray(data) && data?.length > 0) {
  //       data.map((val, i) => {
  //         resultData_.push({
  //           OrgType: "Organization",
  //           id: val?._id ?? "",
  //           entity: val?.name ?? "",
  //           effectiveFrom: "20-06-2021",
  //           effectiveTo: "20-12-2021",
  //           reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
  //           status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
  //           children:
  //             Array.isArray(val?.facility) && val?.facility?.length > 0
  //               ? constructFacility(val?.facility)
  //               : [],
  //         });
  //       });
  //     }
  //     return resultData_;
  //   };

  //   const ConstructJSONNew = (data) => {
  //     debugger;
  //     let resultData = [];
  //     if (Array.isArray(data) && data?.length > 0) {
  //       data.map((val, i) => {
  //         resultData.push({
  //           OrgType: "Enterprise",
  //           id: val?._id ?? "",
  //           entity: val?.name ?? "",
  //           effectiveFrom: "01-03-2021",
  //           effectiveTo: "24-09-2021",
  //           reason: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.reason ?? "",
  //           status: val?.holidayDtls?.[0]?.holidayDtls?.[0]?.status ?? "",
  //           children:
  //             Array.isArray(val?.Organization) && val?.Organization?.length > 0
  //               ? constructOrganization(val?.Organization)
  //               : [],
  //         });
  //       });
  //     }
  //     debugger;
  //     return resultData;
  //   };

  //   const result = ConstructJSONNew(EntityMasters);
  //   setTreeListData(result);
  // }, [EntityMasters]);
  const savefunction = () => {
    let data = {
      type: state.ResourceTypeList,
      role: state.ResourceRole,
      department: state.ResourceDepartment,
      namelist: state.ResourceName,
    };
    // setState({ ...state, Resourse: [...state.Resourse, data] });
    setState({
      ...state,
      Resourse: [...state.Resourse, data],
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      ResourceTypeList: [],
    });
  };
  const specialSave = () => {
    let information = {
      code: state.Instructioncode,
      desc: state.Description,
      criteria: state.CriteriaDoc,
    };
    setState({
      ...state,
      SpecialAppointments: [...state.SpecialAppointments, information],
      Instructioncode: "",
      CriteriaDoc: [],
    });
  };
  const handleshow = () => {
    setSteperSave(false);
  };
  const handleCancel = () => {
    setState({
      ...state,
      open: false,
    });
    setSteperSave(true);
  };
  const handleUpdate = () => {
    let Allresource = state.Resourse;
    Allresource[state.curreteditresourceindex].namelist = state.ResourceName;
    Allresource[state.curreteditresourceindex].department =
      state.ResourceDepartment;
    Allresource[state.curreteditresourceindex].role = state.ResourceRole;
    Allresource[state.curreteditresourceindex].type = state.ResourceTypeList;
    setState({
      ...state,
      Resourse: Allresource,
      curreteditresourceindex: "",
      ResourceName: [],
      ResourceDepartment: [],
      ResourceRole: [],
      ResourceTypeList: [],
    });
  };
  const handlechange = async (key, value, Autocomp, order) => {
    if (key === "Patientcategory") {
      const permittedValues = order.map((val) => {
        return val.value;
      });
      setFinalValue({
        ...finalValue,
        patientcategory: permittedValues,
      });
    }
    // if (Object.keys(value)?.length > 0) {
    //   setState({ ...state, [name]: value });
    // } else if (Array.isArray(value)) {
    //   setState({ ...state, [name]: [...state[name], value] });
    // } else {
    //   setState({ ...state, [name]: value });
    // }
    // setState({ ...state, [name]: value });
    // switch (order) {
    //   case "PRACTROLE":
    //     dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC({ title: value.type }));
    //     break;

    //   case "DEVICEROLE":
    //     dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC({ title: value.type }));
    //     break;

    //   case "LOCATIONROLE":
    //     dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC({ title: value.type }));
    // }
    if (typeof key === "object") {
      setState({
        ...state,
        ...key,
      });
    } else {
      if (typeof value === "string") {
        setState({ ...state, [key]: value });
      } else if (typeof value === "object") {
        if (Array.isArray(value)) {
          if (Autocomp) {
            setState({
              ...state,
              [key]: value?.[0] ?? null,
            });
          } else {
            setState({
              ...state,
              [key]: [...state?.[key], ...value],
            });
          }
        } else {
          setState({
            ...state,
            [key]: { ...state?.[key], ...value },
          });
        }
      }
    }
  };

  const priorityDefinitionSetFinalvalue = (data) =>{
    setFinalValue(prevState =>({
      ...prevState,
      priorityDefinition: [...prevState.priorityDefinition, ...data] 
    }))
    }
  React.useEffect(() => {
    dispatch(actions.RES_PATIENT_CATEGORY_LIST_DOC_QDM());
    dispatch(actions.ENTITY_MASTERS());
    dispatch(actions.RES_RESOURCE_TYPE_LIST_DOC_QDM());
    dispatch(actions.RES_RESOURCE_ROLE_LIST_DOC_QDM());
    dispatch(actions.RES_SPECIAL_INSTRUCTION_LIST_DOC_QDM());
    dispatch(actions.RES_CRITERIA_INSTRUCTION_QDM());

    dispatch(actions.RES_SERVICE_GROUP_QDM());
    dispatch(actions.RES_SERVICE_CATEGORY_QDM());
    dispatch(actions.RES_SERVICE_CODE_QDM());
    dispatch(actions.RES_APPOINTMENT_VITAL_CODE());
    dispatch(actions.RES_APPOINTMENT_BOOKING_TYPE())
  }, []);

  useEffect(() => {
    const datas = TableReadData?.[0];
    if (history?.location?.state?.IsEdit && datas) {
      let bookinobj = {
        _id: datas?.bookingsource?._id,
        label: datas?.bookingsource.display,
        value: datas?.bookingsource?.display,
      };
      let servicedefinitionArr = [];
      datas?.servicedefinition?.map((v,i)=>{
        let obj = {
          servicetype: {
            _id : v?.servicegrouptype?._id,
            label : v?.servicegrouptype?.display,
            value : v?.servicegrouptype?.display,
          },
          servicecategory: {
            _id : v?.servicecategory?._id,
            label : v?.servicecategory?.display,
            value : v?.servicecategory?.display,
          },
          servicecode: {
            _id : v?.servicecode?._id,
            label : v?.servicecode?.display,
            value : v?.servicecode?.display,
          },
        }
        servicedefinitionArr.push(obj);
      })

      let bookingtypeobj={
        _id: datas?.bookingtype?._id,
        label: datas?.bookingtype?.display,
        value: datas?.bookingtype?.display,
      }
      setBookingType(bookingtypeobj)
      let observationdetailsArr = [];
      datas?.observationdetails?.map((v,i)=>{
        let obj = {
          vitalcode: {
            _id : v?._id,
            label : v?.vitaldesc,
            value : v?.vitaldesc,
            loadtype: v?.loadtype,
            resultreportdesc: v?.resultreportdesc,
            resultreport: v?.resultreport,
            resultcodeset: v?.resultcodeset

          },
          sequence:v?.sequence,
          mandatory:v?.mandatory

        }
        observationdetailsArr.push(obj);
      })
      setObservation(observationdetailsArr)

      let resourcedefinitionArr = [];
      datas?.resourcedefinition?.map((v,i)=>{
        let resourceDep = [];
        if (Array.isArray(v?.resourcedepartment)) {
          v?.resourcedepartment?.map((val) => {
            let obj = {
              _id: val?._id,
              label: val?.display,
              Value: val?.display,
            };
            resourceDep?.push(obj);
          });
        }
        let resourceName = [];
        if (Array.isArray(v?.resourcename)) {
          v?.resourcename?.map((val) => {
            let obj = {
              _id: val?._id,
              label: val?.display,
              Value: val?.display,
            };
            resourceName?.push(obj);
          });
        }
        let obj = {
          resourcetype: {
            _id : v?.resourcetype?._id,
            label : v?.resourcetype?.display,
            value : v?.resourcetype?.display,
          },
          resourcerole: {
            _id : v?.resourcerole?._id,
            label : v?.resourcerole?.display,
            value : v?.resourcerole?.display,
          },
          resourcedepartment: resourceDep,
          resourcename: resourceName,
        }
        resourcedefinitionArr.push(obj);
      })

      let appointmentdurationarry = [];
      datas?.appointmentduration?.map((val) => {
        const mappedData = {
          resourcetype: {
            _id: val?.resourcetype?._id || "",
            label: val?.resourcetype?.display,
            Value: val?.resourcetype?.display,
          },
          resourcerole: {
            _id: val?.resourcerole?._id,
            label: val?.resourcerole?.display,
            Value: val?.resourcerole?.display,
          },
          resourcedepartment:
            val.resourcedepartment.map((department) => ({
              _id: department._id,
              label: department.display,
              value: department.display,
            })) || [],
          resourcename:
            val?.resourcename.map((name) => ({
              _id: name._id,
              label: name.display,
              value: name.display,
            })) || [],
          duration: val?.durationinmin,
          apServiceType: {
            _id: val?.servicegrouptype?._id,
            label: val?.servicegrouptype?.display,
            Value: val?.servicegrouptype?.display,
          },
          apServiceCategory: {
            _id: val?.servicecategory?._id,
            label: val?.servicecategory?.display,
            Value: val?.servicecategory?.display,
          },
          apServiceCode: {
            _id: val?.servicecode?._id,
            label: val?.servicecode?.display,
            Value: val?.servicecode?.display,
          }
        };
        appointmentdurationarry.push(mappedData);
      });
      // let patientcategoryarr = [];
      // let servicetypearr = [];
      // if (Array.isArray(datas?.servicetype)) {
      //   datas?.servicetype?.map((val) => {
      //     let obj = {
      //       _id: val?._id,
      //       label: val?.display,
      //       Value: val?.display,
      //     };
      //     servicetypearr?.push(obj);
      //   });
      // }
      // let servicecategoryarr = [];
      // if (Array.isArray(datas?.servicecategory)) {
      //   datas?.servicecategory?.map((val) => {
      //     let obj = {
      //       _id: val?._id,
      //       label: val?.display,
      //       Value: val?.display,
      //     };
      //     servicecategoryarr?.push(obj);
      //   });
      // }
      // let servicecodearr = [];
      // if (Array.isArray(datas?.serviccode)) {
      //   datas?.serviccode?.map((val) => {
      //     let obj = {
      //       _id: val?._id,
      //       label: val?.display,
      //       Value: val?.display,
      //     };
      //     servicecodearr?.push(obj);
      //   });
      // }
      let userrolearr = [];
      if (Array.isArray(datas?.userroles)) {
        datas?.userroles?.map((val) => {
          let obj = {
            _id: val?._id,
            label: val?.display,
            Value: val?.display,
          };
          userrolearr?.push(obj);
        });
      }
      let workQueueObj = {
        _id: datas?.workqueue?._id,
        label: datas?.workqueue?.display,
        value: datas?.workqueue?.display,
      };
      setcheckedData(datas?.statusbyentity ?? [])
      setpriorityEdit(true)
      setFinalValue({
        code: datas?.code ?? "",
        shortdesc: datas?.shortdesc ?? "",
        longdesc: datas?.longdesc ?? "",
        bookingsource: bookinobj ?? "",
        // patientcategory: patientcategoryarr ?? [],
        // servicetype: servicetypearr ?? [],
        // servicecategory: servicecategoryarr ?? [],
        // serviccode: servicecodearr ?? [],
        userroles: userrolearr ?? [],
        specialapptinstructions: datas?.specialapptinstructions ?? [],
        resourcedefinition: resourcedefinitionArr ?? [],
        generalappinstruction: datas?.generalappinstruction ?? [],
        status: datas?.status ?? true,
        statusbyentity: datas?.statusbyentity ?? [],
        // ApptTypeCode: datas?.ApptTypeCode ?? "",
        workQueue: workQueueObj ?? "",
        servicedefinition: servicedefinitionArr ?? [],
        appointmentDuration: appointmentdurationarry ?? [],
        patientInclCriteria: {_id:datas?.patientInclusioncriteria?.[0]?._id,ruleName:datas?.patientInclusioncriteria?.[0]?.rulename},
        observationdetails:observationdetailsArr ?? [],
        bookingtype:bookingtypeobj ?? "",
        priorityDefinition:datas?.prioritydefinition

      });
    }
  }, [TableReadData]);

  return (
    <>
      <div style={{ backgroundColor: "#F6F6F6" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: "8px 16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={backfun}
              style={{
                backgroundColor: "#DCDCDC",
                width: 28,
                height: 28,
                marginRight: 12,
              }}
            >
              <img
                src={backarrow}
                alt="icon"
                style={{ transform: "rotate(90deg)" }}
              />
            </IconButton>
            <p>
              {history?.location?.state?.IsEdit && TableReadData?.[0]?._id  
                ? "Edit Appointment type"
                : "Create Appointment Type"}
            </p>
          </div>
        </div>
        <hr style={{ marginTop: "0px" }} />
      </div>
      <CreateAppointmentTypes
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        error={error}
        // Ediitvalue = {Ediitvalue}
      />
      <BookingSource
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        error={error}
        bookingType={bookingType}
        setBookingType={setBookingType}
      />

      {/* <CreatePatientCategory
        SelectorPatientAuto={PatientCategory}
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        handlechange={handlechange}
        state={state}
      /> */}
      <CreateServiceDefinition
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        Controller={Controller}
        error={error}
        setError={setError}

        ResourceTypeAuto={ResourceDefinitionDrop}
        ResourceRoleAuto={ResourceRoleDrop}
        ResourceDepartmentAuto={ResourceDepartmentDrop}
        ResourceNameAuto={ResourceNametDrop}
        handlechange={handlechange}
        savefunction={savefunction}
        state={state}
        setState={setState}
        TableReadData={TableReadData}
      />
      {/* <CreateUserRole
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        error={error}
      /> */}

      <ResourceDefinitionCard
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        ResourceTypeAuto={ResourceDefinitionDrop}
        ResourceRoleAuto={ResourceRoleDrop}
        ResourceDepartmentAuto={ResourceDepartmentDrop}
        ResourceNameAuto={ResourceNametDrop}
        handlechange={handlechange}
        savefunction={savefunction}
        state={state}
        setState={setState}
        TableReadData={TableReadData}
      />
      <AppointmentDurationCard
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        ResourceTypeAuto={ResourceDefinitionDrop}
        ResourceRoleAuto={ResourceRoleDrop}
        ResourceDepartmentAuto={ResourceDepartmentDrop}
        ResourceNameAuto={ResourceNametDrop}
        handlechange={handlechange}
        savefunction={savefunction}
        state={state}
        setState={setState}
        TableReadData={TableReadData}
      />
      <PatientInclusionCriteria
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        error={error}
        // Ediitvalue = {Ediitvalue}
      />
      <GeneralAppoitmentInstructions
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        TableReadData={TableReadData}
      />
      {/* <Check /> */}
      <SpecialAppoitmentInstructions
        setFinalValue={setFinalValue}
        finalValue={finalValue}
        InstructionCodeAuto={ResourceInstructionCodeDrop}
        criteriaAuto={Criteria}
        TableReadData={TableReadData}
      />
      <StatusBar checkedData={checkedData} handleshow={handleshowPopup} Controller={Controller} />


      <AdditionalInfoTab
        setFinalValue={setFinalValue}
        priorityDefinitionSetFinalvalue={priorityDefinitionSetFinalvalue}
        finalValue={finalValue}
        Controller={Controller}
        error={error}
        setError={setError}
        handlechange={handlechange}
        savefunction={savefunction}
        state={state}
        setState={setState}
        TableReadData={TableReadData}
        observation ={observation}
        setPriorityDefinition={setPriorityDefinitiondata}
        priorityDefinition={priorityDefinitiondata}
        setObservation={setObservation}
        bookingType={bookingType}
        setBookingType={setBookingType}
        valueshow={valueshow}
        setvalueshow={setvalueshow}
        setpriorityEdit={setpriorityEdit}
        priorityEdit={priorityEdit}
        setshowValuepddata={setshowValuepddata}
        showValuepddata={showValuepddata}
      />
      {/* <GenerateFormVersion2
        parent_id={"appointmenttype"}
        setValues={setValues}
        values={values}
        steperSave={steperSave}
        setSteperSave={setSteperSave}
        handleshow={handleshow}
        // steperSave={steperSave}
        // loading={saveloading}
        handlecancel={() => handleCancel()}
        open={state.open}
        clicked={true}
        isAdd={state.isAdd}
        Thdata={[
          {
            Title: "Entity",
            Size: 7,
          },
          {
            Title: "EffectiveFrom",
            Size: 2,
          },
          {
            Title: "EffectiveTo",
            Size: 2,
          },
        ]}
        save={() =>
          setState({
            ...state,
            open: false,
          })
        }
        // handlecancel={handleDrawerClose}
        treeListData={treeListData}
        header="Status By Entity"
        modalStructure={classes.modalStructure}
        // json={[
        //   {
        //     componet_type: "typography",
        //     value: (
        //       <div>
        //         Reason for Holiday:
        //         <span style={{ fontWeight: "bold" }}>Test</span>
        //       </div>
        //     ),

        //     grid: {
        //       md: 2.5,
        //     },
        //     // state_name: "holiday_reason",
        //     // value: "",
        //     // required: true,
        //   },
        //   {
        //     componet_type: "typography",
        //     value: (
        //       <div>
        //         Reason for Holiday:
        //         <span style={{ fontWeight: "bold" }}>Test</span>
        //       </div>
        //     ),
        //     grid: {
        //       md: 2.5,
        //     },
        //     // state_name: "holiday_reason",
        //     // value: "",
        //     // required: true,
        //   },
        //   {
        //     componet_type: "typography",
        //     value: (
        //       <div>
        //         Reason for Holiday:
        //         <span style={{ fontWeight: "bold" }}>Test</span>
        //       </div>
        //     ),
        //     grid: {
        //       md: 2.5,
        //     },
        //     // state_name: "holiday_reason",
        //     // value: "",
        //     // required: true,
        //   },
        //   {
        //     componet_type: "typography",
        //     value: (
        //       <div>
        //         Reason for Holiday:
        //         <span style={{ fontWeight: "bold" }}>Test</span>
        //       </div>
        //     ),
        //     grid: {
        //       md: 2.5,
        //     },
        //     // state_name: "holiday_reason",
        //     // value: "",
        //     // required: true,
        //   },
        //   {
        //     componet_type: "typography",
        //     value: (
        //       <div>
        //         Reason for Holiday:
        //         <span style={{ fontWeight: "bold" }}>Test</span>
        //       </div>
        //     ),
        //     grid: {
        //       md: 2,
        //     },
        //     // state_name: "holiday_reason",
        //     // value: "",
        //     // required: true,
        //   },
        //   steperSave && {
        //     componet_type: "entity",
        //     label: "Add Applicable Entity",
        //     grid: {
        //       md: 1,
        //     },
        //   },

        //   // {
        //   //   componet_type: "date",
        //   //   label: "Date",
        //   //   state_name: "date",
        //   //   value: state?.edit_data?.date ?? "",
        //   //   dateFormat: "dd-MM-yyyy",
        //   //   required: true,
        //   // },
        //   // {
        //   //   componet_type: "checkbox",
        //   //   label: "Repeat Every Year",
        //   //   state_name: "repeat",
        //   //   value: state?.edit_data?.repeat ?? false,
        //   // },
        // ]}
        nocancel
      /> */}
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          float: "right",
          marginRight: "4%",
          paddingBottom: "30px",
        }}
      >
        <Button
          // variant="contained"
          variant="outlined"
          size="medium"
          style={{ marginRight: "14px" }}
          onClick={() => loading ? null : backfun()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() =>loading ? null : saveFuntion()}
        >
          {/* {history?.location?.state?.key ? "Edit" : "Save"} */}
          {history?.location?.state?.IsEdit && TableReadData?.[0]?._id  ? loading ? "Updating..." : "Update" : loading ? "Saving..." : "Save"}
        </Button>
      </div>
      <Dialog fullWidth maxWidth={"lg"} onClose={()=>setState({...state,open: false,})}
       aria-labelledby="customized-dialog-title" open={state?.open}>
        <DialogTitle id="customized-dialog-title" onClose={()=>setState({...state,open: false,})}>
          Status By Entity
        </DialogTitle>
        <Divider style={{ borderBottom: "2px solid lightGray" }}/>
        <DialogContent dividers>
        <Grid style={{
          paddingTop:"10px",
          paddingLeft:"20px"
        }}>
          <TreeComponent
            data={treeListData}
            setcheckedData={setcheckedData}
            checkedData={checkedData}
            setState={setState}
            state={state}
            isData={checkedData ? true : false}
          />
        </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default withHoverDrawer(withAllContexts(AddAppointmentType));
