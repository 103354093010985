import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { TopNavBar, HoverNavBar, VerticalList } from '../..'
import { CommonTable } from '../..';
import ApiConfig from './apiConfig';
import { AlertProps, AxiosCall, searchquery, entity } from '../../../utils';
import { AlertContext } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: "auto",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56
    // },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0
    },
    // marginTop: "60px",
    // marginLeft: "10px",
  },
  topNavbar: {
  },
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
}));
const styles = {
  outlinedButton: {
    borderColor: "#DEDEDE",
    color: "#2A60BC",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  outlinedDelButton: {
    borderColor: "#FF4D4A",
    color: "#FF4D4A",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  modalHeading: {
    fontSize: 16,
    color: "#2A60BC",
  },
}
const ApiConfigList = (props) => {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const alert = useContext(AlertContext);

  const classes = useStyles({ props });
  const [state, setState] = useState();
  const [isFormOpen, setisFormOpen] = useState({
    isForm: false,
    isEdit: false,
    isView: false,
  });
  const [openAlert, setOpenAlert] = useState({
    open: false,
    index: null
  });
  const [ApiConfigList, setApiConfigList] = useState({
    _id: "",
    apiname: "",
    apidescription: "",
    apiinputschema: {},
    apiurl: "",
    isSavedNew: false
  });
  const [apiConfig, setapiConfig] = useState({
    _id: "",
    apiname: "",
    apidescription: "",
    apiinputschema: {},
    apiurl: "",
  });

  const handleForm = () => {
    setisFormOpen({
      ...isFormOpen,
      isForm: true,
      isEdit: false,
      isView: false,
    });
  }
  const getApiConfigData = async (id, pageDetails, searchVal) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_METADATA_DB_NAME,
        filter: {
          searchname: searchVal ?? "",
          eventapiid: id ?? "",
          page: pageDetails?.page ?? 0,
          perPage: pageDetails?.perPage ?? 10
        },
        queryid: searchquery
      }
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      let result = []
      if (Array.isArray(Resp)) {
        Resp?.map((v, i) => {
          let obj = {
            _id: v?._id,
            apiname: v?.apiname,
            apidescription: v?.apidescription,
            apiinputschema: v?.apiinputschema == {} ? "" : v?.apiinputschema,
            apiurl: v?.apiurl,
            isSavedNew: false,
            totalcount: v?.totalcount,
          }
          result.push(obj)
        })
        id ? setapiConfig(result) : setApiConfigList(result);
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handlePageChange = async (currentPage, PerPage) => {
    if (PerPage) {
      setState({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
      await getApiConfigData("", { page: (currentPage - 1) * PerPage, perPage: PerPage });
    }
  };
  const handleEdit = (e, row, index) => {
    getApiConfigData(row?._id);
    setisFormOpen({
      ...isFormOpen,
      isForm: false,
      isEdit: true,
      isView: false,
    });
  }
  const handleView = (e, row, index) => {
    getApiConfigData(row?._id);
    setisFormOpen({
      ...isFormOpen,
      isView: true,
      isEdit: false,
      isForm: false,
    });
  }
  const handleDelete = async (del) => {
    try {
      if (del?.open) {
        let deleteData = ApiConfigList[del?.index];
        let delPayload = {
          db_name: process.env.REACT_APP_METADATA_DB_NAME,
          entity: entity,
          filter: `${entity}._id == '${deleteData?._id}'`,
          metadata_dbname: process.env.REACT_APP_MetadataDB_Name
        }
        let Resp = await AxiosCall(delPayload, process.env.REACT_APP_SOFT_DELETE)
        if (Resp) {
          setOpenAlert({
            ...openAlert,
            open: false,
            index: null
          })
          if (Resp?.Code === 201) {
            getApiConfigData()
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Deleted Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          } else {
            alert?.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Delete Failed",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          }
        } else {
          alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "please contact admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleSearch = (searchVal) => {
    getApiConfigData("", "", searchVal);
  }

  useEffect(() => {
    getApiConfigData();
    setapiConfig({
      _id: "",
      apiname: "",
      apidescription: "",
      apiinputschema: {},
      apiurl: "",
    })
  }, [ApiConfigList?.isSavedNew])

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      {/* {props.type !== "APICONFIG" && ( */}
        {/* <>
          <div className={classes.topNavbar}>
            <TopNavBar />
          </div>

          <div className={classes.hovarNavBar}>
            <HoverNavBar />
          </div>
        </> */}
      {/* )} */}

      {/* Content */}
      <div className={classes.content}>
        {isFormOpen?.isForm || isFormOpen?.isView || isFormOpen?.isEdit ? (
          <ApiConfig
            setisFormOpen={setisFormOpen}
            setApiConfigList={setApiConfigList}
            isFormOpen={isFormOpen}
            apiConfig={apiConfig[0]}
            setapiConfig={setapiConfig}
          />
        ) : (
          <VerticalList
            parent_id={"api_manamement"}
            addFunc={handleForm}
            // searchCmpProps starts here
            id={"apiconfig"}
            placeholder={"search name & description"}
            // loading={true}
            onSearchChange={handleSearch}
            hideSearchBar={false}
            hideFilter={true}
            // onFilterClicked={props?.onFilterClicked}
            // isFilterApplied={props?.isFilterApplied}          
            TabList={[
              {
                name: "API Configuration",
                component: (
                  <CommonTable
                    parent_id={"api_manamement"}
                    rightAlign={["View", "Action"]}
                    handleEdit={handleEdit}
                    handleView={handleView}
                    handleDelete={(e, v, i) => setOpenAlert({
                      open: true,
                      index: i
                    })}
                    Header={[
                      { label: "S.No", showSort: false, showFilter: false },
                      { label: "API Name", showSort: false, showFilter: false },
                      { label: "API Description", showSort: false, showFilter: false },
                      { label: "URL", showSort: false, showFilter: false },
                      { label: "Action", showSort: false, showFilter: false },
                      { label: "View", showSort: false, showFilter: false },
                    ]}
                    dataList={ApiConfigList ?? []}
                    tableData={[
                      { type: ["INCRIMENT"], name: "1" },
                      { type: ["TEXT"], name: "apiname" },
                      { type: ["TEXT"], name: "apidescription" },
                      { type: ["TEXT"], name: "apiurl" },
                      { type: ["EDIT", "DELETE"], name: "", align: "right" },
                      { type: ["VIEW",], name: "", align: "right" },
                    ]}
                    handlepageChange={(currentPage, PerPage) =>
                      handlePageChange(currentPage, PerPage)
                    }
                    TableCount={ApiConfigList?.[0]?.totalcount}
                    incrementCount={state?.page}
                    showPagination={true}
                    rowsPerPageOptions={[
                      { label: "10 Rows", value: 10 },
                      { label: "50 Rows", value: 50 },
                      { label: "100 Rows", value: 100 },
                    ]}
                  />
                ),
              },
            ]}
          />
        )}
      </div>
      {openAlert?.open && (
        <Dialog
          fullScreen={fullScreen}
          open={openAlert?.open}
          onClose={() => setOpenAlert({
            ...openAlert,
            open: false
          })}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle style={styles.modalHeading} id="alert-dialog-title">{"Are you Sure Want to Delete ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this record? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "15px" }}>
            <Button onClick={() => setOpenAlert({
              ...openAlert,
              open: false
            })} color="primary"
              style={styles.outlinedButton}
              variant="contained"
            >
              No
            </Button>
            <Button onClick={() => handleDelete(openAlert)} color="primary"
              style={styles.outlinedDelButton}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default ApiConfigList