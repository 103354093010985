import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Col, Div, H6, Row, TextInput } from 'qdm-component-library';
import { CommonTabList, SelectBox } from '../../common';
import { AlertProps, AxiosCall, extractIdAndValue, upsertapiConnector } from '../../../utils';
import { AlertContext } from '../../../contexts';
// import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    addBtn: {
        float: 'right',
        fontSize: '14px',
        fontWeight: 600,
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        marginTop: '70px',
        marginLeft: '0px',
        marginRight: '0px',
    },
    topNavbar: {},
    sideNavbar: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hovarNavBar: {
        display: 'flex',
        position: 'relative',
        top: '63px',
    },
    commanTabbar: {
        display: 'flex',
        position: 'relative',
        top: '70px',
        marginLeft: '80px',
    },
    ".MuiSvgIcon-root": {
        display: "none"
    },
}));

const ApiConnector = ({ formConnect, setFormConnect, ...props }) => {
    const classes = useStyles();
    const [connectorType, setconnectorType] = useState([]);
    const [type, setType] = useState([]);
    const [dataType, setdataType] = useState([]);
    const [source, setSource] = useState([]);
    const [authType, setauthType] = useState([]);
    const [method, setMethod] = useState([]);
    const [formError, setformError] = React.useState(false);
    const alert = useContext(AlertContext);

    React.useEffect(() => {
        getConnectorType()
        getType();
        getDataType()
        getSource()
        getAuthorisation()
        getMethod()
    }, []);

    useEffect(()=>{
      if(props?.editValue){

       setFormConnect({
            connectortype:{_id:props?.editValue?.[0]?.connectortype?._id,
                             label:props?.editValue?.[0]?.connectortype?.display,
                             value:props?.editValue?.[0]?.connectortype?.display},
            baseurl: props?.editValue?.[0]?.baseurl,
            type:{_id:props?.editValue?.[0]?.headerparam?.[0]?.type?._id,
                   label:props?.editValue?.[0]?.headerparam?.[0]?.type?.display,
                   value:props?.editValue?.[0]?.headerparam?.[0]?.type?.display},
            key: props?.editValue?.[0]?.headerparam?.[0]?.key,
            datatype: {_id:props?.editValue?.[0]?.headerparam?.[0]?.datatype?._id,
                     label:props?.editValue?.[0]?.headerparam?.[0]?.datatype?.display,
                     value:props?.editValue?.[0]?.headerparam?.[0]?.datatype?.display},
            source:  {_id:props?.editValue?.[0]?.headerparam?.[0]?.source?._id,
                     label:props?.editValue?.[0]?.headerparam?.[0]?.source?.display,
                     value:props?.editValue?.[0]?.headerparam?.[0]?.source?.display},
            value: props?.editValue?.[0]?.headerparam?.[0]?.value,
            authtype:  {_id:props?.editValue?.[0]?.authtype?._id,
                      label:props?.editValue?.[0]?.authtype?.display,
                     value:props?.editValue?.[0]?.authtype?.display},
            method:  {_id:props?.editValue?.[0]?.method?._id,
                     label:props?.editValue?.[0]?.method?.display,
                     value:props?.editValue?.[0]?.method?.display},
            endpoint: props?.editValue?.[0]?.endpoint,
        })
      }
      
    },[props?.editValue])

    const getConnectorType = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["CONNECTORTYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setconnectorType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getType = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["HEADERPARAMTYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getDataType = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["CONNECTORDATATYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setdataType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getSource = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["CONNECTORSOURCE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setSource(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAuthorisation = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["AUTHTYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setauthType(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getMethod = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["CONNECTMETHOD"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setMethod(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleChange = (key, value) => {
        setFormConnect({
            ...formConnect,
            [key]: value,
        });
    };


    const onSave = async () => {
        if (!formConnect?.baseurl || !formConnect?.authtype) {
            setformError(true);  // Set form error state to true
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill in the mandatory fields (Base URL and Auth Type).",  
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            return;  
        }

        // Additional validation for the base URL format (to ensure it's a valid URL, for example)
        const isValidUrl = (url) => {
            const pattern = new RegExp('^(https?:\/\/)?([a-z0-9]+[.]){1,}[a-z]{2,6}(\/[a-z0-9#]+\/?)*$', 'i');
            return pattern.test(url);
        }

        if (!isValidUrl(formConnect?.baseurl)) {
            setformError(true);  // Set form error state to true
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please provide a valid Base URL.",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            return; 
        }

        //Edit Payload
        let payload
        if (props?.editValue?.[0]?._id) {
            payload = [
                {
                    db_name: process.env.REACT_APP_DATABASE_NAME,
                    entity: upsertapiConnector,
                    is_metadata: true,
                    metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                    metadataId: process.env.REACT_APP_METADATAID,
                    filter: {
                        _id: props?.editValue?.[0]?._id
                    },
                    doc: {
                        connectortype: formConnect.connectortype._id,
                        baseurl: formConnect.baseurl,
                        headerparam: [
                            {
                                type: formConnect.type._id,
                                key: formConnect.key,
                                datatype: formConnect.datatype._id,
                                source: formConnect.source._id,
                                value: formConnect.value
                            }
                        ],
                        authtype: formConnect.authtype_id,
                        method: formConnect.method._id,
                        endpoint: formConnect.endpoint

                    },
                },
            ];

        }

        //save payload
        else {
            payload =
                [
                    {
                        db_name: process.env.REACT_APP_DATABASE_NAME,
                        entity: upsertapiConnector,
                        is_metadata: true,
                        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                        metadataId: process.env.REACT_APP_METADATAID,
                        doc: {
                            connectortype: formConnect.connectortype._id ?? "",
                            baseurl: formConnect.baseurl ?? "",
                            headerparam: [
                                {
                                    type: formConnect.type._id ?? "",
                                    key: formConnect.key ?? "",
                                    datatype: formConnect.datatype._id ?? "",
                                    source: formConnect.source._id ?? "",
                                    value: formConnect.value ?? ""
                                }
                            ],
                            authtype: formConnect.authtype._id ?? "",
                            method: formConnect.method._id ?? "",
                            endpoint: formConnect.endpoint ?? ""
                        }
                    }
                ]
        }
        let Resp = await AxiosCall(payload, process.env.REACT_APP_UPSERT_DOC);

        if (Resp.Code === 201) {
            props?.getApiConnectData();
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: `${props?.ApiConnector?._id ? `Saved Successfully! ID: ${props?.ApiConnector?._id}` : "Saved Successfully!"}`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            setFormConnect({
                connectortype: "",
                baseurl: "",
                type: "",
                datatype: "",
                source: "",
                key: "",
                value: "",
                authtype: "",
                method: "",
                endpoint: "",
            });
        }
        props.setisFormOpen({
            isView: false,
            isEdit: false,
            isForm: false,
        })
    };

    const onCancel = () => {
        props.setisFormOpen(false)
    }
    return (
        <div className={classes.root}>
            <Div>
                <CommonTabList
                    parent_id={'admintabpanel'}
                    title='API CONNECTOR'
                    list={[]}
                    backbtn={true}
                    backFun={() => {
                        props?.setisFormOpen({
                            ...props?.isFormOpen,
                            isForm: false,
                            isView: false,
                            isEdit: false,
                        });
                    }}
                />
            </Div>
            <Paper>
                <Div style={{ padding: "60px" }}>
                    <Div>
                        <Grid container spacing={2} direction='column'>
                            <Grid item>
                                <Box>
                                    <Div>
                                        <Row>
                                            <Col lg={4} inLineStyles={{ padding: 12 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    Connector Type
                                                </Typography>
                                                <SelectBox
                                                    size='medium'
                                                    name='connectortype'
                                                    value={formConnect?.connectortype}
                                                    list={extractIdAndValue("CONNECTORTYPE", connectorType)}
                                                    placeholder='API'
                                                    disabled={true}
                                                    onchange={(e, v) => handleChange('connectortype', v)}
                                                />
                                            </Col>
                                            <Col lg={6} inLineStyles={{ padding: 16 }}>
                                                <TextInput
                                                    label={
                                                        <>
                                                            Base Url<span style={{ color: "red" }}> *</span>
                                                        </>
                                                    }
                                                    name='base url'
                                                    value={formConnect?.baseurl}
                                                    placeholder='Enter url'
                                                    onChange={(e, v) => {
                                                        handleChange('baseurl', e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Div>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Paper>
                                    <Div>
                                        <Div>                                         
                                            <Row>
                                                <Col lg={10} inLineStyles={{ padding: 10 }}>
                                                    <H6 className="pi-title"style={{
                                                            fontSize: '14px',
                                                        }} >
                                                        {"Headers & Params"}
                                                        </H6>
                                                </Col>
                                                {/* <Col lg={2}>
                                                    <Button
                                                        className={classes.addBtn}
                                                        color="primary"

                                                    >
                                                        + Add
                                                    </Button>
                                                </Col> */}
                                            </Row>

                                            <Row >
                                                <Col lg={2} inLineStyles={{ padding: 12 }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        variant='body1'
                                                    >
                                                        Type
                                                    </Typography>
                                                    <SelectBox
                                                        size='medium'
                                                        name='type'
                                                        value={formConnect?.type}
                                                        list={extractIdAndValue("HEADERPARAMTYPE", type)}
                                                        placeholder='Select Type'
                                                        onchange={(e, v) => handleChange('type', v)}
                                                    />
                                                </Col>

                                                <Col lg={2} inLineStyles={{ padding: 16 }}>
                                                    <TextInput
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        label='Key'
                                                        name='key'
                                                        value={formConnect?.key}
                                                        onChange={(e, v) => handleChange('key', e.target.value)}
                                                    />
                                                </Col>

                                                <Col lg={2} inLineStyles={{ padding: 12 }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        variant='body1'
                                                    >
                                                        Data Type
                                                    </Typography>
                                                    <SelectBox
                                                        size='medium'
                                                        name='datatype'
                                                        value={formConnect?.datatype}
                                                        list={extractIdAndValue("CONNECTORDATATYPE", dataType)}
                                                        placeholder='Select Type'
                                                        onchange={(e, v) => handleChange('datatype', v)}
                                                    />
                                                </Col>

                                                <Col lg={2} inLineStyles={{ padding: 12 }}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            fontFamily: 'pc_regular',
                                                            color: '#6F6F6F',
                                                        }}
                                                        variant='body1'
                                                    >
                                                        Source
                                                    </Typography>
                                                    <SelectBox
                                                        size='medium'
                                                        name='source'
                                                        value={formConnect?.source}
                                                        list={extractIdAndValue("CONNECTORSOURCE", source)}
                                                        placeholder='Select Type'
                                                        onchange={(e, v) => handleChange('source', v)}
                                                    />
                                                </Col>

                                                <Col lg={2} inLineStyles={{ padding: 16 }}>
                                                    <TextInput
                                                        label='Value'
                                                        name='value'
                                                        value={formConnect?.value}
                                                        onChange={(e, v) => handleChange('value', e.target.value)}
                                                    />
                                                </Col>

                                                {/* <Col lg={2} inLineStyles={{ padding: 16 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            <IconButton aria-label="delete">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </Col> */}
                                            </Row>
                                        </Div>
                                    </Div>
                                </Paper>
                            </Grid>

                            <Grid item>
                                <Paper>
                                    <Div>
                                        <Row>

                                            <Col lg={10} inLineStyles={{ padding: 12 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    Authorisation Type<span style={{ color: 'red' }}> *</span>
                                                </Typography>
                                                <SelectBox
                                                    size='large'
                                                    name='authtype'
                                                    value={formConnect?.authtype}
                                                    list={extractIdAndValue("AUTHTYPE", authType)}
                                                    placeholder='Select Type'
                                                    onchange={(e, v) => {
                                                        handleChange('authtype', v);
                                                    }}
                                                />
                                            </Col>

                                            <Col lg={4} inLineStyles={{ padding: 12 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    Method (Check Service)
                                                </Typography>
                                                <SelectBox
                                                    size='large'
                                                    name='method'
                                                    value={formConnect?.method}
                                                    list={extractIdAndValue("CONNECTMETHOD", method)}
                                                    placeholder='Select Type'
                                                    onchange={(e, v) => {
                                                        handleChange('method', v);
                                                    }}
                                                />
                                            </Col>

                                            <Col lg={4} inLineStyles={{ padding: 16 }}>
                                                <TextInput
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    label='End Point (Check Service)'
                                                    name='endpoint'
                                                    value={formConnect?.endpoint}
                                                    onChange={(e, v) => {
                                                        handleChange('endpoint', e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Div>
                                </Paper>
                            </Grid>

                            <Grid id={'agerange-form-rangedetails-grid'} style={{ padding: '20px' }}>
                                <Button
                                    id={'agerange_form_rangedetails_Save_button'}
                                    style={{ float: 'right' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={onSave}
                                >
                                    {props?.editValue?.length ? "Update" : "Save"}
                                </Button>
                                <Button
                                    id={'agerange_form_rangedetails_Cancel_button'}
                                    style={{ float: 'right', marginRight: '20px' }}
                                    variant='outlined'
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Div>
                </Div>

            </Paper>
        </div>
    )
}

export default ApiConnector