import React, { useEffect, useState } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Building from "../../components/availabilitymanagement/holidayTreeTable/Images/buildings.svg";
import Organization from "../../assets/organization.svg";
import { Button, Divider, Grid, makeStyles } from '@material-ui/core';
import { id } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  facilityCancel: {
    background: "#ffe9e3",
    padding: 6,
    borderRadius: 7,
    color: "#ec6a49",
    textTransform: "none",
    fontSize: 16,
    fontFamily: "pc_semibold",
    "&:hover": {
      background: "#ffe9e3",
    },
  },
  normalBtn: {
    background: "#fff",
    padding: "0px 15px",
    borderRadius: 7,
    color: "#000",
    textTransform: "none",
    fontSize: 16,
    fontFamily: "pc_semibold",
  },
  saveBtn: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 7,
    textTransform: "none",
    fontSize: 16,
    fontFamily: "pc_semibold",
    background: "#0071f2",
    color: "#fff",
    "&:hover": {
      background: "#0071f2",
    },
  },
}));
const TreeComponent = (props) => {

  const classes = useStyles();
  const [checked, setChecked] = useState([]);

  const handlesave = () => {
    props?.setcheckedData(checked)
    props?.setState({...props?.state,open: false})
  };

  const toggleCheck = (id, isChecked) => {
    setChecked((prev) => {
      let updatedChecked = [...prev];

      if (isChecked) {
        if (!updatedChecked.includes(id)) {
          updatedChecked.push(id);
        }
      } else {
        updatedChecked = updatedChecked.filter((item) => item !== id);
      }
      const updateChildren = (node) => {
        node.Organization?.forEach((child) => {
          const childId = child._id;
          if (isChecked) {
            if (!updatedChecked.includes(childId)) {
              updatedChecked.push(childId);
            }
          } else {
            updatedChecked = updatedChecked.filter((item) => item !== childId);
          }
          updateChildren(child);
        });
        node.facility?.forEach((child) => {
          const childId = child._id;
          if (isChecked) {
            if (!updatedChecked.includes(childId)) {
              updatedChecked.push(childId);
            }
          } else {
            updatedChecked = updatedChecked.filter((item) => item !== childId);
          }
        });
      };

      // Find node in data and update recursively
      const findNodeAndUpdate = (nodes) => {
        nodes.forEach((node) => {
          if (node._id === id) {
            updateChildren(node);
          } else {
            if (node.Organization) findNodeAndUpdate(node.Organization);
          }
        });
      };

      findNodeAndUpdate(props?.data);

      return updatedChecked;
    });
  };

  const renderTree = (nodes, orgType) => (
    <TreeItem
      key={nodes._id}
      nodeId={nodes._id}
      label={
        <>
          <div style={{ display: 'flex', alignItems: 'center', lineHeight: '1.5' }}>
            <Checkbox
              color="primary"
              disabled={props?.isView ? true : false}
              checked={checked.includes(nodes._id)}
              onChange={(e) => toggleCheck(nodes._id, e.target.checked)}
            />
            <span style={{ marginLeft: '10px', fontWeight: 'bold', fontSize: '14px' }}>{nodes.name}</span>
          </div>
        </>
      }
      style={{
        margin: '12px',
      }}
    >
      {nodes.Organization?.map((node) => renderTree(node))}
      {nodes.facility?.map((node) => renderTree(node))}
    </TreeItem>
  );

  useEffect(()=>{
    if(props?.isData){
        setChecked(props?.checkedData)
    }
  },[props?.isData])

  return (
    <>
    <TreeView
      aria-label="customized"
      defaultCollapseIcon={<img src={Building} alt="enterprise" />}
      defaultExpandIcon={<img src={Building} alt="enterprise" />}
    >
      <Grid>
        {props?.data.map((node) => renderTree(node))}
      </Grid>
    </TreeView>
    <Grid container item md={12} justifyContent="flex-end" alignItems="center">
          
          <Button
            id={`generateform_Cancel_button`}
            variant={"outlined"}
            style={{ margin:5 }}
            size={"small"}
            contained
            onClick={() =>props?.setState({...props?.state,open: false})}
            className={classes.facilityCancel}
            disableRipple
            disableElevation
          >
            Cancel
          </Button>
          {props?.isView ? null : (
            <Button
                id={`_generateform_Save_button`}
                // disabled={props?.loading ?? false}
                variant={"contained"}
                style={{ margin: 5 }}
                size={"small"}
                contained
                onClick={() => {
                handlesave();
                }}
                className={classes.saveBtn}
                disableRipple
                disableElevation
            >
                {"save"}
            </Button>
          )}
          
        </Grid>
    </>
  );
};

export default TreeComponent;