import { Divider, Grid } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
// import CustomDropdown from '../../../../components/atoms/CustomDropdown/Dropdown';
import buildings from "../../../assets/buildings.svg";
import calender from "../../../assets/calendar.svg";
import content from "../../../assets/content.svg";
// import ViewStatus from "../../../pages/ChargeMaster/AddChargeMaster/ChargeDefinition/Viewstatus";
import Card from "../../../components/Card/Card";
import IconLabel from "../../../components/IconLabel/iconLabel";
import { StatusComponent } from "../../../components/common/smartForm/component/StatusComponent/statusComponent";
import { Image } from "qdm-component-library";
// import StatusComponent from '../../atoms/StatusComponent/Status';
/**
 *
 * @param {object} props - required props in StatusBar
 * @returns {React.ReactElement} - returns the StatusBar component
 */
function StatusBar(props) {
  const { parent_id, handleshow, Controller } = props;
  // const { cardTitle, onOpenModal, onClickHandleAction } = props;
  // const [open, setOpen] = React.useState(false);
  // const datastatus = [
  //   {
  //     Image: buildings,
  //     head: "Enterprise",
  //     body: "AINQA - INDIA",
  //   },
  //   {
  //     Image: calender,
  //     head: "Effective From",
  //     body: "12-06-2020",
  //   },
  //   {
  //     Image: calender,
  //     head: "Effective To",
  //     body: "12-06-2020",
  //   },
  //   {
  //     Image: content,
  //     head: "Reason",
  //     body: "Quisque suscipit ipsum est, eu venenatis leo ornare ege.",
  //   },
  // ];

  return (
    <Grid>
      <Card
        cardTitle={<>
        {"STATUS BY ENTITY"}
          {props?.checkedData?.length > 0 ? (
                  <Image
                    id={`${parent_id}-image`}
                    style={{ width: 16, height: 16, marginLeft: 5 }}
                    src="images/icons8-ok.svg"
                    alt="tick"
                  />
                ) : null }
        </>}
        cardTitleType="caption"
        optionalHeader={
          <button
            id={`${parent_id}-entity-button`}
            style={{
              border: "none",
              color: "#0071f2",
              textDecoration: "underline",
              backgroundColor: "#ffffff",
              fontSize: "12px",
              fontFamily: "poppin",
              cursor: "pointer",
              paddingRight: 10,
            }}
            onClick={() => handleshow()}
          >
            <div
              id={`${parent_id}-View-holidays-entities-div`}
              style={{ display: "flex" }}
            >
              <div
                id={`${parent_id}-View-holidays-entities-sub-div`}
                style={{ fontFamily: "poppin", fontSize: "12px" }}
              >
                <img
                  id={`${parent_id}-View-holidays-entities-door-img`}
                  src={buildings}
                  alt="Icon"
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginTop: "3px",
                    filter:
                      "invert(-20%) sepia(17%) saturate(5273%) hue-rotate(201deg) brightness(98%) contrast(102%)",
                  }}
                />
                {!Controller ? "Status by Entity" : " View Status by Entity"}
              </div>
            </div>
          </button>
        }
        customStyle={{
          marginLeft: "16px",
          marginTop: "20px",
          padding: "18px",
          width: "95%",
          fontFamily: "poppinsemibold",
        }}
      >
        {/* {open && (
          <ViewStatus
            onCloseModal={(d) => setOpen(d)}
            onClickHandleAction={() => onClickHandleAction()}
          />
        )} */}
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-between"
          xs={9}
          className="statusgrid"
        >
          {/* <IconLabel EntryData={datastatus} /> */}
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          light
          flexItem
          sx={{ border: "2px solid #DEE5EC", mr: 3, width: "2px" }}
        />
        <Grid item xs={2} container justifyContent="flex-start">
          {/* <CustomTooltip tooltipTitle="status"> */}
          {/* <StatusComponent enable overAllStatus /> */}
          {/* </CustomTooltip> */}
        </Grid>
      </Card>
    </Grid>
  );
}
export default StatusBar;
StatusBar.propTypes = {
  onOpenModal: PropTypes.func,
  // OpenController: PropTypes.bool,
  //   headCells: PropTypes.arrayOf(PropTypes.objectOf),
  //   rowData: PropTypes.arrayOf(PropTypes.objectOf),
  cardTitle: PropTypes.string,
  onClickHandleAction: PropTypes.func,
  //   isDrawer: PropTypes.bool
};
StatusBar.defaultProps = {
  onOpenModal: () => null,
  //   headCells: [],
  //   rowData: [],
  cardTitle: "",
  onClickHandleAction: () => null,
  // OpenController: false
};
