import React, { useContext, useEffect, useState } from "react";
import backarrow from "../../../assets";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
  Card,
  Button,
  Popover,
  Radio,
  Checkbox,
  Switch,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { CommonTabList, SelectBox } from "../../../components";
import { tr } from "date-fns/locale";
import { findAllByDisplayValue } from "@testing-library/react";
import { AlertContext } from "../../../contexts";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { AlertProps, AxiosCall } from "../../../utils";
import { useHistory } from "react-router";
import Config from "../../../config";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Tab } from "qdm-component-library";
import CreateBookingType from "./AppTypeAdditionalInfo";
import AppTypeAdditionalInfo from "./AppTypeAdditionalInfo";
import AppTypepriorityDefinition from "./AppTypePriorityDefinition";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        margin: 15,
        borderRadius: 10,
        width: "95%",
      },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 4,
    color: "grey",
  },
  formParent: {
    paddingRight: 10,
    marginLeft: 0,
  },
}));

function AdditionalInfoTab(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const service = useSelector((state) => state.resourceByAppointmentTypesSlice); 
  const {
    setFinalValue,
    finalValue,
    TableReadData,
    observation, 
    setObservation,
    bookingType,
    setBookingType,
    setState,
    handlechange,
    Controller,
    error,
    setError,
    savefunction,
    state,
    setPriorityDefinition,
    priorityDefinition,
    valueshow,
    setvalueshow,
    priorityEdit,
    setpriorityEdit,
    setshowValuepddata,
    showValuepddata,


  } = props;
  const classes = useStyles();
  return (
    <>
    <div>
         <Paper className={classes.root}>
          <CommonTabList
            title=""
            list={[
              {
                name: "Additional Info",
                component: <AppTypeAdditionalInfo 
                setFinalValue={setFinalValue}
                finalValue={finalValue}
                Controller={Controller}
                error={error}
                setError={setError}
                handlechange={handlechange}
                savefunction={savefunction}
                state={state}
                setState={setState}
                TableReadData={TableReadData}
                observation ={observation}
                setObservation={setObservation}
                bookingType={bookingType}
                setBookingType={setBookingType}
                valueshow={valueshow}
                setvalueshow={setvalueshow}
                />,
   

              },
              {
                name: "Priority Definition",
                component: <AppTypepriorityDefinition 
                setFinalValue={setFinalValue}
                finalValue={finalValue}
                Controller={Controller}
                error={error}
                setError={setError}
                handlechange={handlechange}
                savefunction={savefunction}
                state={state}
                setState={setState}
                TableReadData={TableReadData}
                observation ={observation}
                setObservation={setObservation}
                bookingType={bookingType}
                setBookingType={setBookingType}
                setPriorityDefinition={setPriorityDefinition}
                priorityDefinition={priorityDefinition}
                valueshow={valueshow}
                setvalueshow={setvalueshow}
                priorityDefinitionSetFinalvalue={props?.priorityDefinitionSetFinalvalue}
                priorityEdit ={priorityEdit}
                setpriorityEdit={setpriorityEdit}
                showValuepddata={showValuepddata}
                setshowValuepddata={setshowValuepddata}
                />,
              },
            ]}
          />
          </Paper>
    </div>
    
    </>
  );
}

export default AdditionalInfoTab;
