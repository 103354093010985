import React, { useContext, useEffect, useState } from "react";
import backarrow from "../../../assets";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
  Card,
  Button,
  Popover,
  Radio,
  Checkbox,
  Switch,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { SelectBox } from "../..";
import { tr } from "date-fns/locale";
import { findAllByDisplayValue } from "@testing-library/react";
import { AlertContext } from "../../../contexts";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { AlertProps, AxiosCall } from "../../../utils";
import { useHistory } from "react-router";
import Config from "../../../config";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Tab } from "qdm-component-library";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 4,
    color: "grey",
  },
  formParent: {
    paddingRight: 10,
    marginLeft: 0,
  },
}));

function AppTypeAdditionalInfo(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const service = useSelector((state) => state.resourceByAppointmentTypesSlice); 
  const {
    setFinalValue,
    finalValue,
    TableReadData,
    observation, 
    setObservation,
    bookingType,
    setBookingType,
    setState,
    handlechange,
    Controller,
    savefunction,
    state,
    setvalueshow,
    valueshow
  } = props;
  const classes = useStyles();
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editshow, seteditshow] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentEditIndex(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
    setObservation({
        vitalcode: "",
        mandatory: false,
        sequence: ""
    });
  };
  const handleChangelist = async (key, value) => {
      setObservation({
        ...observation,
        [key]: value,
      });
  };

  const handleEditvalue = (v, i) => {
    seteditshow(true);
    setCurrentEditIndex(i);
    setObservation({
        vitalcode: v?.vitalcode,
        mandatory: v?.mandatory,
        sequence: v?.sequence
    });
  };

  const deleteResources = (index) => {
    setFinalValue((prevFinalValue) => {
      const updatedobservationdetails = [...prevFinalValue.observationdetails];
      updatedobservationdetails.splice(index, 1);
      return {
        ...prevFinalValue,
        observationdetails: updatedobservationdetails,
      };
    });

    setvalueshow((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues.splice(index, 1);
      return updatedValues;
    });
  };

  const handlesubmit = () => {
    if (currentEditIndex !== null) {
      if( observation?.vitalcode?.label &&
        observation?.sequence ){
          let updatedObervation = finalValue.observationdetails 
          updatedObervation[currentEditIndex] = observation;
          setFinalValue({
            ...finalValue,
            observationdetails:updatedObervation
          })

          const updatedValues = [...valueshow];
          updatedValues[currentEditIndex] = observation;
          setvalueshow(updatedValues);
          setObservation({
            vitalcode: "",
            sequence: "",
            mandatory: false
          });
          setCurrentEditIndex(null);
          setAnchorEl(null);
          seteditshow(false);
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      if (
        observation?.vitalcode?.label &&
        observation?.sequence
      ) {
        if (valueshow.some(item => item?.vitalcode?._id === observation?.vitalcode?._id
        )) {
          alert?.setSnack({
              open: true,
              severity: AlertProps.severity.warning,
              msg: "Existing vitalcode Can't Be Added Again!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
          });
          return ;
      }
      if (valueshow.some(item => item?.sequence === observation?.sequence
      )) {
        alert?.setSnack({
            open: true,
            severity: AlertProps.severity.warning,
            msg: "Existing sequence Can't Be Added Again!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
        });
        return ;
    }
        const updatedValues = [...valueshow, observation];
        setvalueshow(updatedValues);
        setError(false)
        setFinalValue({
          ...finalValue,
          observationdetails:updatedValues
        })

      } else {
        setError(true)
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill all the mandatory fields!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
        return;
      }
      setObservation({
        vitalcode: "",
        mandatory: false,
        sequence: ""
      });
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if(bookingType.label=="single"){
        setObservation({})
    }
  },
  [bookingType])

  useEffect(() => {
    const resourceData = TableReadData[0];
    if (history?.location?.state?.IsEdit && resourceData ) {
      let observationDetailsArray = [];
      resourceData?.observationdetails?.map((val) => {
        const mappedData = {
          vitalcode: {
            _id: val?._id || "",
            label: val?.vitaldesc,
            Value: val?.vitaldesc
          },
          sequence: val?.sequence,
          mandatory: val?.mandatory,
        };
        observationDetailsArray.push(mappedData);
      });
      setvalueshow(observationDetailsArray);
    }
  }, [TableReadData]);

  
  return (
    <>
      
        <div style={{ padding: 18  }}>
          <Grid container spacing={2} lg={12} alignItems="center">
                <Grid
                   item lg={3} md={3}
                >
                    <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Vital Code{" "}
                    </Typography>
                    <SelectBox
                    parent_id={"vitalCode-select"}
                    value={observation?.vitalcode ?? []}
                    onchange={(e, v) =>
                      handleChangelist("vitalcode",v)
                    }
                    list={
                       Array.isArray(service?.res_appointment_vitalCode?.data)
                        ? service?.res_appointment_vitalCode?.data
                        : []
                    }
                    
                    // error={error && !resourse?.servicetype?.label}
                    />
                </Grid>
                <Grid
                    item lg={3} md={3}
                >
                    <Typography
                    style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Sequence
                    </Typography>
                    <TextField
                    id={`Sequence-Code-textField`}
                    // disabled={Boolean(editData)}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={observation?.sequence ?? []}
                    onChange={(e) => {
                      if (/^[0-9\s@\/]*$/.test(e.target.value)) {
                          handleChangelist("sequence", Number(e.target.value ?? ""))
                      }
                    }
                  }
                    />
                </Grid>
                <Grid
                    item lg={3} md={3}
                >
                    <Typography
                   style={{
                      color: "#6F6F6F",
                      fontSize: 14,
                      marginBottom: 4,
                      marginTop: 0,
                    }}
                    >
                    Mandatory
                    </Typography>
                    <Switch
                    checked={observation?.mandatory}
                    onClick={(e) => handleChangelist("mandatory",e.target.checked)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    value={observation?.mandatory}
                    />  
                                     
                </Grid>
                <Grid item lg={3} md={3}>
                <Button
            variant="outlined"
            size="medium"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            // color="primary"
            style={{
              marginRight: "34px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
            onClick={() => handlesubmit()}
          >
            {editshow ? "update" : "+ADD"}
          </Button>
                </Grid>

              </Grid>
        </div>
      
        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {valueshow?.length > 0 &&
            valueshow?.map((v, i) => {
              return (
                <Grid
                  container
                  style={{
                    border: "1px solid #E0E0E0",
                    padding: 12,
                    // marginBottom: 8,
                    backgroundColor: "#F9F9F9",
                    position: "relative",
                  }}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={1}
                        style={{
                          backgroundColor: "#E0E0E0",
                          position: "absolute",
                          left: 0,
                          padding: "6px 12px",
                          borderTopRightRadius: 9,
                          borderBottomRightRadius: 9,
                        }}
                      >
                        {i + 1}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginLeft: "4%",
                          display: "flex",
                          padding: 10,
                        }}
                      >
                        {/* <div>{v.type.label}</div>
                        <div>
                          {v.role ? v.role.label : v.role ? v.role : " "}
                          {""}
                          <div className={classes.dot} />

                          {v.department.map((v) => v.label).join()}

                          {""}
                          <div className={classes.dot} />

                          {v.namelist.map((v) => v.label).join()}

                          {""}
                        </div> */}
                         <Grid style={{ marginLeft: "10px" 
                         }}>
                          {v.vitalcode?.label}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.sequence}
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }}>
                          {v.mandatory?"Mandatory":""}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="EditIcon"
                        className={classes.margin}
                        size="small"
                      >
                        <EditIcon
                          onClick={(e) => {
                            handleClick(e);
                            handleEditvalue(v, i);
                          }}
                          style={{ color: "#0071F2", fontSize: 18 }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="DeleteIcon"
                        className={classes.margin}
                        size="small"
                        onClick={() => deleteResources(i)}
                      >
                        <DeleteIcon
                          style={{ color: "#FF4D4A", fontSize: 18 }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Card>
    </>
  );
}

export default AppTypeAdditionalInfo;
