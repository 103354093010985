import React, { useContext, useEffect, useState } from "react";
import backarrow from "../../../assets";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
  Card,
  Button,
  Popover,
  Radio,
  Checkbox,
  Switch,
  Box,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { SelectBox } from "../..";
import { tr } from "date-fns/locale";
import { findAllByDisplayValue } from "@testing-library/react";
import { AlertContext } from "../../../contexts";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { AlertProps, AxiosCall } from "../../../utils";
import { useHistory } from "react-router";
import Config from "../../../config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Div, Tab } from "qdm-component-library";
import { data } from "jquery";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    margin: 15,
    borderRadius: 10,
    width: "95%",
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 4,
    color: "grey",
  },
  formParent: {
    paddingRight: 10,
    marginLeft: 0,
  },
}));

function AppTypepriorityDefinition(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const service = useSelector((state) => state.resourceByAppointmentTypesSlice); 
  const {
    setFinalValue,
    finalValue,
    TableReadData,
    observation, 
    setObservation,
    bookingType,
    setPriorityDefinition,
    priorityDefinition,
    setBookingType,
    setState,
    handlechange,
    Controller,
    savefunction,
    state,
    valueshow,
    setvalueshow,
    priorityEdit,
    setshowValuepddata,
    showValuepddata
  } = props;
  const classes = useStyles();
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editshow, seteditshow] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [combodetail, setComboDetail] = useState({});
  const [showValuepd, setshowValuepd] = useState([]);
  const [priorityState,setpriorityState ] = useState([]);
  // const [showValuepddata, setshowValuepddata] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentEditIndex(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

const getPriority = useSelector(
    (state) =>
      state.resourceByAppointmentTypesSlice.res_appointment_generalpriority_Load.data
  );

  useEffect(() => {
    if (valueshow) {
        valueshow.forEach(detail => {
        if (detail?.vitalcode?.resultreportdesc === "Dropdown" ||detail?.vitalcode?.resultreportdesc === "Combobox" ) {
            getCombBoxlist(detail);
        }
      });
    }
  }, [valueshow]);

useEffect(async() => {
     await dispatch(actions.RES_APPOINTMENT_GENERALPRIORITY_LOAD("APPPRIORITY"));

  }, []);

useEffect(() =>{
    if(priorityEdit){
      handleEdit()
    }
},[])

useEffect(() => {
  setshowValuepddata(prevState => [...prevState]);
}, []);


//   const handleClose = () => {
//     setAnchorEl(null);
//     setPriorityDefinition({
//         vitalcode: "",
//         mandatory: false,
//         sequence: ""
//     });
//   };
//   const handleChangelist = async (key, value) => {
//     setPriorityDefinition({
//         ...priorityDefinition,
//         [key]: value,
//       });
//   };

//   const handleEditvalue = (v, i) => {
//     seteditshow(true);
//     setCurrentEditIndex(i);
//     setPriorityDefinition({
//         vitalcode: v?.vitalcode,
//         mandatory: v?.mandatory,
//         sequence: v?.sequence
//     });
//   };

//   const deleteResources = (index) => {
//     setFinalValue((prevFinalValue) => {
//       const updatedobservationdetails = [...prevFinalValue.observationdetails];
//       updatedobservationdetails.splice(index, 1);
//       return {
//         ...prevFinalValue,
//         observationdetails: updatedobservationdetails,
//       };
//     });

//     setvalueshow((prevValues) => {
//       const updatedValues = [...prevValues];
//       updatedValues.splice(index, 1);
//       return updatedValues;
//     });
//   };

// const handleChange = (event, value, vitalcode, isAutocomplete = true) => {

//     debugger;
//     let updatedValues;
//     let updatedValuesshow;
//     if (isAutocomplete) {
//         if(vitalcode === "priority"){
//             updatedValuesshow = {
//                 ...showValuepd,
//                 priority:[
//                     {
//                         data:value,
//                     }
//                     ]
//                  }
//             updatedValues = { ...selectedValues, priority: value };
//         }
//         else{
//       updatedValuesshow = {
//         ...showValuepd,
//          [vitalcode?.label]:[
//             {
//                 data:value,
//                 controldata:vitalcode
//             }
//             ]
//          }
//          updatedValues = { ...selectedValues, [vitalcode?._id]: value };
//         }
      
      
//     } else {
//         updatedValuesshow = {
//             ...showValuepd,
//              [vitalcode?.label]:event.target.value }
//       updatedValues = { ...selectedValues, [vitalcode?._id]: event.target.value };
//     }
//     setshowValuepd(updatedValuesshow)
//     setSelectedValues(updatedValues);
//     // setAppoinmantAddtionalDetails(updatedValues);
//   };

const handleChange = (event, value, vitalcode, isAutocomplete = true) => {
    let updatedValues;
    let updatedValuesshow;
    if (isAutocomplete) {
        if(vitalcode === "priority"){
            setpriorityState(value)
            updatedValuesshow = {
                ...showValuepd,
                priority:[
                    {
                        data:value,
                    }
                    ]
                 }
            updatedValues = { ...selectedValues, priority: value };
            
        }
        else{
      updatedValuesshow = {
        ...showValuepd,
         [vitalcode?.label]:[
            {
                data:value,
                controldata:vitalcode
            }
            ]
         }
         updatedValues = { ...selectedValues, [vitalcode?._id]: value };
        }
     
     
    } else {
        updatedValuesshow = {
            ...showValuepd,
             [vitalcode?.label]:event}
      updatedValues = { ...selectedValues, [vitalcode?._id]: event};
    }
    setshowValuepd(updatedValuesshow)
    setSelectedValues(updatedValues);
    
    // setAppoinmantAddtionalDetails(updatedValues);
  };

// const handlesubmit = () => {
//     debugger
//     console.log(selectedValues,"selectedValues");
    
//     const additionalDetail = Object.entries(selectedValues).map(([vitalCode, value]) => ({
//         obscode: vitalCode,
//         obsvalue: Array.isArray(value)
//           ? value.map(v => (v._id ? v._id : v.value))
//           : [value]
//       }));
//     //   const labels = extractLabels(showValuepd);
//       setshowValuepddata(showValuepd)
//       setSelectedValues([])
//       console.log(additionalDetail,"combodetail");
// }

const priorityDefinitionSet= (data)  => {
    props?.priorityDefinitionSetFinalvalue (data)
    }

const handlesubmit = () => {
    const priorityValue = selectedValues.priority?.value ?? priorityState?._id;
    const additionalDetail = [];
    Object.keys(selectedValues).forEach(key => {
        const item = selectedValues[key];
        if (Array.isArray(item)) {
            let obsvalueArry =[]
            item.forEach(subItem => {
              obsvalueArry.push(subItem._id)

            });
            const updatedItem = {
              obscode: key,
              obsvalue: obsvalueArry,
          };

          if (priorityValue) {
              updatedItem.priority = priorityValue;
          }

          additionalDetail.push(updatedItem);

        }
        else if (item && !Array.isArray(item) && typeof item === 'object') {
            const updatedItem = {
                obscode: key,
                obsvalue: [item._id],
                priority: priorityValue || item._id, 
            };
 
            additionalDetail.push(updatedItem);
        }
        else if (item && typeof item !== 'object' && !Array.isArray(item)) {
            const updatedItem = {
                obscode: key,
                obsvalue: [item],
                priority: priorityValue || item._id, 
            };
 
            additionalDetail.push(updatedItem);
        }
    });
    const filteredData = additionalDetail.filter(item => item.obscode !== "priority");
 
    priorityDefinitionSet(filteredData)
      setshowValuepddata(prevState => [
        ...prevState,
        showValuepd   
      ]);
    setSelectedValues([]);
    setpriorityState([])
    
};

// function transformData(data1) {
//   const groupedData = {};
  
//   data1.forEach(entry => {
//       const priorityId = entry.priority._id;
//       const priorityLabel = entry.priority.display;
//       const category = entry.obscode.vitalscode;
      
//       if (!groupedData[priorityId]) {
//           groupedData[priorityId] = {
//               priority: [{
//                   data: {
//                       _id: priorityId,
//                       value: priorityId,
//                       label: priorityLabel
//                   }
//               }]
//           };
//       }
      
//       if (!groupedData[priorityId][category]) {
//           groupedData[priorityId][category] = [{ data: [] }];
//       }
      
//       entry.obsvalue.forEach(obs => {
//           groupedData[priorityId][category][0].data.push({
//               label: obs.longdesc,
//               value: obs.longdesc,
//               _id: obs._id
//           });
//       });
//   });
  
//   return Object.values(groupedData);
// }


function transformData(data1, data3) {
  const groupedData = {};
  const controlDataMap = {};
  data3.forEach(control => {
      controlDataMap[control.vitalcode.value] = {
          _id: control.vitalcode._id,
          value: control.vitalcode._id,
          label: control.vitalcode.label,
          resultreportdesc: control.vitalcode.resultreportdesc,
          loadtype: control.vitalcode.loadtype,
          resultcodeset: control.vitalcode.resultcodeset
      };
  });
  data1.forEach(entry => {
      const priorityId = entry.priority._id;
      const priorityLabel = entry.priority.display;
      const category = entry.obscode.vitalscode;
      if (!groupedData[priorityId]) {
          groupedData[priorityId] = {
              priority: [{
                  data: {
                      _id: priorityId,
                      value: priorityId,
                      label: priorityLabel
                  }
              }]
          };
      }
      if (!groupedData[priorityId][category]) {
          groupedData[priorityId][category] = [{ data: [], controldata: controlDataMap[category] || {} }];
      }
      entry.obsvalue.forEach(obs => {
          groupedData[priorityId][category][0].data.push({
              label: obs.longdesc,
              value: obs.longdesc,
              _id: obs._id
          });
      });
  });
  return Object.values(groupedData);
}


const handleEdit = () =>{

  setvalueshow(finalValue?.observationdetails)
  

  let fieldId;
  let controlData
  const editDatacConstract=finalValue.priorityDefinition

 
  setshowValuepddata(transformData(editDatacConstract,finalValue?.observationdetails))
  // editDatacConstract?.map((i) => {

  //    fieldId = i?.obscode?._id
  //    finalValue?.observationdetails?.map((vitaldata)=>{
  //       if(vitaldata?.vitalcode == fieldId){
  //         controlData=vitaldata
  //       } 
  //     })
  // })


}


  const transformInnerEditData = (data1) => {
    const transformedData = {};
    Object.keys(data1).forEach((category) => {
      if (category === "priority"){
        const setpriority =data1[category]?.[0]?.data
        setpriorityState(setpriority)
       return;
      }
      const categoryData = data1[category];
      categoryData.forEach(item => {
        const controlId = item.controldata._id;
        if (!transformedData[controlId]) {
          transformedData[controlId] = [];
        }
        item.data.forEach(dataItem => {
          transformedData[controlId].push({
            label: dataItem.label,
            value: dataItem.value,
            _id: dataItem._id
          });
        });
      });
    });
  
    return transformedData;
    
  };


const handleInnerEdit = (v,i) =>{
  const setdata=transformInnerEditData(i)
  setSelectedValues(setdata)
}




const getCombBoxlist=async(key) =>{
    const data = key?.vitalcode?.loadtype
    const codeSet=key?.vitalcode?.resultcodeset
    if (data === "GM") {
        const GMResponse = await dispatch(actions.RES_APPOINTMENT_DYNAMIC_DATA_LOAD(codeSet));
        const responseData = GMResponse?.payload?.data;
        
        const detail = responseData.map(dat =>
          dat?.[codeSet]?.Value.map(dd => ({
                label: dd?.display,
                value: dd?.display,
                _id: dd?._id,})
         )
        ).flat();
   
        setComboDetail(prevState => ({
            ...prevState,
            [codeSet]: detail,
          }));
    }

    if (data === "query") {
        const queryResponse = await dispatch(actions.RES_APPOINTMENT_DYNAMIC_QUERYDATA_LOAD(codeSet));
        const queryData = queryResponse?.payload?.data;
   
        const detail = queryData.map(query => ({
            label: query?.display,
            value: query?.display,
            _id: query?._id,
        }));
   
        setComboDetail(prevState => ({
          ...prevState,
          [codeSet]: detail,
        }));
    }


}

// const extractLabels = (showValuepd) => {
//     let extractedLabels = [];

//     for (const key in data) {
//       if (Array.isArray(data[key])) {
//         // If it's an array (like Bloodgroup), extract labels from each item in the array
//         data[key].forEach(item => {
//           if (item.label) extractedLabels.push(item.label);
//         });
//       } else if (data[key].label) {
//         // If it's a single object (like Gender), push the label from that object
//         extractedLabels.push(data[key].label);
//       } else if (typeof data[key] === 'string') {
//         // If it's a regular string (like "name"), just add the value
//         extractedLabels.push(data[key]);
//       }
//     }

//     return extractedLabels;
//   };





  return (
    <>
    <Div style={{ padding: 18  }}>
          <Grid container  spacing={3}xs={12}  alignItems="center">
      {valueshow &&
        valueshow.map((valueshow, index) => (
             <> 
              {valueshow?.vitalcode?.resultreportdesc=== "Dropdown" ? (
                <Grid item xs={3}>
                <Typography variant="body1" style={{ marginBottom: "8px" }}>
                {valueshow?.vitalcode?.label}
              </Typography>
                <SelectBox
                multi={true}
                fullWidth
                size="small"
                list={combodetail[valueshow?.vitalcode?.resultcodeset] || []}
                value={selectedValues[valueshow?.vitalcode?._id]|| []}
                onchange={(e, value) => handleChange(e, value, valueshow?.vitalcode, true)}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                style={{padding:"0px"}}
              />
                </Grid>
            
             
              ) : valueshow?.vitalcode?.resultreportdesc === "Combobox" ? (
                <Grid item xs={3}>
                <Typography variant="body1" style={{ marginBottom: "8px" }}>
                {valueshow?.vitalcode?.label}
              </Typography>
                <SelectBox
                list={combodetail[valueshow?.vitalcode?.resultcodeset] || []}
                onchange={(e, value) => handleChange(e, value, valueshow?.vitalcode, true)}
                value={selectedValues[valueshow?.vitalcode?._id]|| []}
                //   id={}
                //   options={}
                //   value={}
                //   onChange={}
                //   placeholder="Select..."
                // //   isRequired={detail.mandatory}
                //   inLineStyles={{
                //     padding: "0px",
                //     width: "100%",
                //   }}
                />
                </Grid>
                
 
              )
               : valueshow?.vitalcode?.resultreportdesc === "Textbox" ? (

                <Grid item xs={3}>
                <Typography variant="body1" style={{ marginBottom: "8px" }}>
                {valueshow?.vitalcode?.label}
              </Typography>
                <TextField
                //   fullWidth
                //   size="small"
                //   variant="outlined"
                //   className={classes.textField}
                value={selectedValues[valueshow?.vitalcode?._id]|| []}
                 onChange={(e) => handleChange(e.target.value, null,valueshow?.vitalcode, false)}
                />
                </Grid>
                
              ) :valueshow?.vitalcode?.resultreportdesc === "Number" ? (
                <Grid item xs={3}> 
                <Typography variant="body1" style={{ marginBottom: "8px" }}>
                {valueshow?.vitalcode?.label}
              </Typography>
                <TextField
                  fullWidth
                //   type="number"
                   size="small"
                    variant="outlined"
                    lassName={classes.textField}
                    value={selectedValues[valueshow?.vitalcode?._id]|| []}
                    onChange={(e) =>
                         handleChange(parseInt(e.target.value), null, valueshow?.vitalcode, false)}
                /></Grid>
               
              ) : null}
    
            </>

          ))}
           <Grid item xs={3}>
                              <Typography
                              style={{
                                color: "#6F6F6F",
                                fontSize: 14,
                                marginBottom: 4,
                                marginTop: 0,
                              }}
                              >
                              Priority
                              </Typography>
                              <SelectBox
                              id={`Sequence-Code-textField`}
                              // disabled={Boolean(editData)}
                              fullWidth
                              list={getPriority||[]}
                              variant="outlined"
                              size="small"
                              onchange={(e, value) => handleChange(e, value,"priority", true)}
                              value={priorityState ?? []}
                            //   onChange=     {}
                            
                              />
        </Grid>
 
        <Grid item lg={3} md={3}>
                <Button
            variant="outlined"
            size="medium"
            style={{ marginRight: "20px" }}
            // onClick={() => {
            //   handleClose();
            // }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            // color="primary"
            style={{
              marginRight: "34px",
              backgroundColor: "#0071F2",
              color: "white",
            }}
             onClick={() => handlesubmit()}
          >
            {editshow ? "update" : "Add criteria"}
          </Button>
        </Grid>
        </Grid>
</Div>




    {showValuepddata.map((i, index) => (
    <Card
        style={{
          marginTop: "10px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "20px",
          backgroundColor: "#f9f9f9",
        }}
      >
     <Grid container xs={12} alignItems="center" justifyContent="space-between">
        <Grid item xs={11} >
        <Grid container>
        {Object.keys(i).map((key) => {
          if (i[key]?.[0]?.controldata?.resultreportdesc === "Dropdown") {
            const data = i[key]?.[0]?.data;
            return (
                <>
                {data.map((j, idx) => (
                  <Grid key={j.label} style={{ fontSize: "16px", margin: "0 10px" }}>
                    {j.label}{idx < data.length - 1 && " - "}
                  </Grid>
                ))}
                </>
            );
          }
          if (i[key]?.[0]?.controldata?.resultreportdesc === "Combobox" || key === "priority") {
            const data = i[key]?.[0]?.data;
            return (
             <>
                <Grid key={data?.label} style={{ margin: "0 10px", fontSize: "16px" }}>
                  {data?.label}{" - "}
                </Grid>
           </>
            );
          }
          if (typeof i[key] !== 'object' && !Array.isArray(i[key])) {
            return (
        
                <Grid key={data?.label} style={{ margin: "0 10px", fontSize: "16px" }}>
                  {i[key]}{" - "}
                </Grid>
               
            );
          }

          return null;
        })}
        </Grid>
        
        </Grid>
        <Grid item  xs={1}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconButton
            aria-label="EditIcon"
            className={classes.margin}
            size="small"
            onClick={(e) => {
              handleInnerEdit(e,i);
            }}
          >
            <EditIcon
              style={{ color: "#0071F2", fontSize: 18 }}
            />
          </IconButton>
          <IconButton
            aria-label="DeleteIcon"
            className={classes.margin}
            size="small"
          >
            <DeleteIcon
              style={{ color: "#FF4D4A", fontSize: 18 }}
            />
          </IconButton>
        </Grid>
        </Grid>
        
        
    </Grid>
    </Card>
    ))}


    </>
  );
}

export default AppTypepriorityDefinition;
