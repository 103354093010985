import React, { useContext, useEffect, useState } from 'react'
import { HoverNavBar, TopNavBar } from '../../navbars'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { CommonTable, VerticalList } from '../../common';
import ApihubConfi from './apihubConfig';
import { AlertProps, AxiosCall, Searchqueryid, upsertapihubentity } from '../../../utils';
import { AlertContext } from '../../../contexts';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  content: {
    width: "98%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    // [theme.breakpoints.up("sm")]: {
    //   paddingLeft: 56,
    // },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
    // marginTop: "70px",
    // marginLeft: "30px",
  },
  topNavbar: {},
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hovarNavBar: {
    display: "flex",
    position: "relative",
    top: "63px",
  },
  commanTabbar: {
    display: "flex",
    position: "relative",
    top: "70px",
    marginLeft: "80px"
  }
}));
const styles = {
  outlinedButton: {
    borderColor: "#DEDEDE",
    color: "#2A60BC",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  outlinedDelButton: {
    borderColor: "#FF4D4A",
    color: "#FF4D4A",
    backgroundColor: "#fff",
    borderRadius: 8,
    fontSize: 14,
    fontFamily: "pc_regular !important",
    display: "block",
    whiteSpace: "nowrap",
  },
  modalHeading: {
    fontSize: 16,
    color: "#2A60BC",
  },
}
const ApihubConfiList = (props) => {
  const alert = useContext(AlertContext);
  const classes = useStyles();
  const [isFormOpen, setisFormOpen] = useState({
    isForm: false,
    isEdit: false,
    isView: false,
  });
  const [editValue, seteditValue] = useState();
  const [state, setState] = useState();
  const [queryHide, setqueryHide] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    index: null
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ApihubConfigList, setApihubConfigList] = useState([]
    // _id: "",
    // description: "",
    // datasourcetype: "",
    // qdmQuery: {},
    // module: "",
    // functionality: '',
    // userId: '',
    // password: '',
    // api: '',
    // isSavedNew: false
  );

  const [ApihubConfig, setApihubConfig] = useState({
    _id: "",
    description: '',
    datasourcetype: '',
    arangoQuery: '',
    module: '',
    functionality: '',
    userId: '',
    password: '',
    api: ''
  });

  const [formState, setFormState] = useState({
    id: '',
    description: '',
    dataSourceType: '',
    arangoQuery: '',
    module: '',
    functionality: '',
    userId: '',
    password: '',
    api: '',
  });

  const handleForm = () => {
    seteditValue([])
    setFormState(
      {
        id: "",
        description: "",
        dataSourceType: "",
        arangoQuery: "",
        module: "",
        functionality: "",
        userId: "",
        password: "",
        api: "",
      }
    )
    setisFormOpen({
      ...isFormOpen,
      isForm: true,
      isEdit: false,
      isView: false,
    });
  };

  //Get Api for List
  const getApiConfigData = async (id, pageDetails, searchVal) => {
    try {
      let payload = {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        filter: {
          searchname: searchVal ?? "",
          page: pageDetails?.page ?? 0,
          perPage: pageDetails?.perPage ?? 10,
        },
        queryid: Searchqueryid
      }
      let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
      let result = []
      if (Array.isArray(Resp)) {
        Resp?.map((v, i) => {
          let obj = {
            _id: v?._id,
            queryid: v?.queryid,
            description: v?.description,
            datasourcetype: v?.datasourcetype,
            // isSavedNew: false,
            TotalCount: v?.TotalCount,
          }
          result.push(obj)
        })
        // id ? setApihubConfig(result) : setApihubConfigList(result);
        setApihubConfigList(result);
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleEdit = (e, row, index) => {
    const selectedRow = ApihubConfigList[e];
    let val = [];
    val.push(selectedRow)
    seteditValue(val)
    if (selectedRow?.datasourcetype == "Internal") {
      setisFormOpen({
        ...isFormOpen,
        isForm: false,
        isEdit: true,
        isView: false,
      });
      setqueryHide(false)
    }
    else {
      setisFormOpen({
        ...isFormOpen,
        isForm: false,
        isEdit: true,
        isView: false,
      });
      setqueryHide(true)
    }
  }

  const handleDelete = async (e, row, index) => {
    const selectedRow = ApihubConfigList[e.index];

    //Delete Payload//
    let payload = [
      {
        db_name: process.env.REACT_APP_DATABASE_NAME,
        entity: upsertapihubentity,
        is_metadata: false,
        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
        metadataId: process.env.REACT_APP_METADATAID,
        filter: {
          _id: selectedRow?._id,
        },
        doc: {
          activestatus: false,
        },
      },
    ];
    let Resp = await AxiosCall(payload, process.env.REACT_APP_UPSERT_DOC);
    if (Resp) {
      setOpenAlert({
        ...openAlert,
        open: false,
        index: null
      })
      if (Resp?.Code === 201) {

        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Deleted Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      } else {
        alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Delete Failed",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
          tone: true,
        });
      }
    }
    getApiConfigData(); // validation msg for delete
  }

  const handleView = (e, row, index) => {
    const selectedRow = ApihubConfigList[e];
    let val = [];
    val.push(selectedRow)
    seteditValue(val)
    setisFormOpen({
      ...isFormOpen,
      isView: true,
      isEdit: false,
      isForm: false,
    });
  }

  const handleSearch = (searchVal) => {
    getApiConfigData("", "", searchVal);
  }

  const handlePageChange = async (currentPage, PerPage) => {
    await getApiConfigData("", { page: (currentPage - 1) * PerPage, perPage: PerPage });
  };

  useEffect(() => {
    getApiConfigData();
    setApihubConfig({
      _id: "",
      description: '',
      datasourcetype: '',
      arangoQuery: '',
      module: '',
      functionality: '',
      userId: '',
      password: '',
      api: ''
    })
  }, [ApihubConfigList?.isSavedNew])

  return (
    <div>
    {/* {props.type !== "APIHUBCONFIG" && ( */}
      {/* <>
        <div className={classes.topNavbar}>
          <TopNavBar />
        </div>

        <div className={classes.hovarNavBar}>
          <HoverNavBar />
        </div>
      </> */}
    {/* )} */
    }


      <div className={classes.content}>
        {isFormOpen?.isForm || isFormOpen?.isView || isFormOpen?.isEdit ?
          <ApihubConfi
            setisFormOpen={setisFormOpen}
            isFormOpen={isFormOpen}
            setApihubConfigList={setApihubConfigList}
            queryHide={queryHide}
            editValue={editValue}
            getApiConfigData={getApiConfigData}
            formState={formState}
            setFormState={setFormState}

          /> :
          <VerticalList
            parent_id={"api_manamement"}
            addFunc={handleForm}
            // searchCmpProps starts here
            id={"apihubconfig"}
            placeholder={"search id & description"}
            // loading={true}
            onSearchChange={handleSearch}
            hideSearchBar={false}
            hideFilter={true}
            // onFilterClicked={props?.onFilterClicked}
            // isFilterApplied={props?.isFilterApplied}          
            TabList={[
              {
                name: "API Hub Configuration",
                component: (
                  <CommonTable
                    parent_id={"api_manamement"}
                    rightAlign={["View", "Action"]}
                    handleEdit={(e, data, index) => {
                      handleEdit(index);
                    }}
                    handleView={(e, data, index) => {
                      handleView(index);
                    }}
                    // handleDelete={(e, data, index) => {
                    //   handleDelete(index);
                    // }}
                    handleDelete={(e, data, i) => setOpenAlert({
                      open: true,
                      index: i
                    })}
                    Header={[
                      { label: "S.No", showSort: false, showFilter: false },
                      { label: "ID", showSort: false, showFilter: false },
                      { label: "Description", showSort: false, showFilter: false },
                      { label: "Data Source Type", showSort: false, showFilter: false },
                      { label: "Action", showSort: false, showFilter: false },
                      { label: "View", showSort: false, showFilter: false },
                    ]}
                    dataList={ApihubConfigList ?? []}
                    tableData={[
                      { type: ["INCRIMENT"], name: "1" },
                      { type: ["TEXT"], name: "queryid" },
                      { type: ["TEXT"], name: "description" },
                      { type: ["TEXT"], name: "datasourcetype" },
                      { type: ["EDIT", "DELETE"], name: "", align: "right" },
                      { type: ["VIEW",], name: "", align: "right" },
                    ]}
                    handlepageChange={(currentPage, PerPage) =>
                      handlePageChange(currentPage, PerPage)
                    }
                    TableCount={ApihubConfigList?.[0]?.TotalCount}
                    incrementCount={formState?.page}
                    showPagination={true}
                    rowsPerPageOptions={[
                      { label: "10 Rows", value: 10 },
                      { label: "50 Rows", value: 50 },
                      { label: "100 Rows", value: 100 },
                    ]}
                  />
                ),
              },
            ]}
          />
          }
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={openAlert?.open}
        onClose={() => setOpenAlert({
          ...openAlert,
          open: false
        })}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle style={styles.modalHeading} id="alert-dialog-title">{"Are you Sure Want to Delete ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this record? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "15px" }}>
          <Button onClick={() => setOpenAlert({
            ...openAlert,
            open: false
          })} color="primary"
            style={styles.outlinedButton}
            variant="contained"
          >
            No
          </Button>
          <Button onClick={() => handleDelete(openAlert)} color="primary"
            style={styles.outlinedDelButton}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  
  )
}

export default ApihubConfiList