import React, { useEffect ,useState} from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import { actions } from "primary_care_admin_binder";
//import { useDispatch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
}));

function VitalCodeDescription(props) {
  const classes = useStyles();
  const [vitalType, setVitalType] = useState([]);
  const [controlType, setControlType] = useState([]);
  const dispatch = useDispatch();

  //let dispatch = useDispatch();
  const storeData = useSelector((state) => state?.vitalsMasterSectionSlice);
  const Data = useSelector((state) => state?.vitalsMasterSectionSlice?.getGeneralDataforVitalMaster);


useEffect(async() => {

      const vitalTypeData= await dispatch(actions.GET_GENERAL_DATA_VITAL_TYPE("OBSERVATIONTYPE"))
      setVitalType(vitalTypeData?.payload?.data)

      const controlTypeData= await dispatch(actions.GET_GENERAL_DATA_CONTROL_TYPE("RESULTREPORTDATATYPE"))
      setControlType(controlTypeData?.payload?.data)
      
      
    }, []);

  // React.useEffect(() => {
  //   getMasterData();
  // }, []);

  // const getMasterData = async () => {
  //   await dispatch(actions.UNIT_ALLOWED());
  // };

  const txtFieldChange = (e) => {
    if ((e.which >= 65 && e.which <= 122) || e.which === 32) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };
  const {
    code,
    longdes,
    shortdes,
    unitAllowed,
    defaultUnit,
    handleFormState,
    parent_id,
    obstype,
    resultreport,
    resultcodeset,
    error,
  } = props;
  return (
    <React.Fragment>
      <Paper id={`${parent_id}_parent_paper`} className={classes.root}>
        <Typography
          id={`${parent_id}_parent_title_typography`}
          className={classes.title}
          variant="h6"
        >
          VITALS CODE DESCRIPTION
        </Typography>
        <Grid
          id={`${parent_id}_parent_grid`}
          container
          className={classes.form}
        >
          <Grid
            id={`${parent_id}_code_input_grid`}
            md="6"
            lg="4"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_sublevel_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Vitals Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}_code_textField`}
              onChange={(e) => handleFormState("code", e.target.value?.replaceAll(/[.*+,><?^${}()"|[\]\\" "]/g,'')?.toLocaleUpperCase())}
              value={code}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              error={error?.code}
              disabled={props?.editData}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Short_input_grid`}
            md="6"
            lg="4"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Short_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Short Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}_Short_textField`}
              value={shortdes}
              onChange={(e) => handleFormState("shortdes", e.target.value)}
              onKeyPress={(e) => txtFieldChange(e)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              error={error?.shortdes}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Long_input_grid`}
            md="6"
            lg="4"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Long_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Long Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}_Long_textField`}
              value={longdes}
              onKeyPress={(e) => txtFieldChange(e)}
              onChange={(e) => handleFormState("longdes", e.target.value)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              error={error?.longdes}
            />
          </Grid>

          <Grid
            id={`${parent_id}_Units_SelectBox_grid`}
            md="6"
            lg="6"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Units_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Units Allowed <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              id={`${parent_id}_unitAllowed_Selectbox`}
              parent_id={`${parent_id}_unitAllowed_Selectbox`}
              list={storeData.unit_allowed.data ?? []}
              multi
              placeholder={"Select"}
              value={unitAllowed}
              onchange={(e, value) => handleFormState("unitAllowed", value)}
              error={error?.unitAllowed}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Default_SelectBox_grid`}
            md="2"
            lg="2"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Default_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Default Unit <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              id={`${parent_id}_defaultUnit_SelectBox`}
              parent_id={`${parent_id}_defaultUnit_SelectBox`}
              list={storeData.default_unit.data ?? []}
              //list={unitAllowed}
              placeholder={"Select"}
              value={defaultUnit}
              onchange={(e, value) => handleFormState("defaultUnit", value)}
              error={error?.defaultUnit}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Default_SelectBox_grid`}
            md="2"
            lg="2"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Default_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Vital Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              id={`${parent_id}_defaultUnit_SelectBox`}
              // parent_id={`${parent_id}_defaultUnit_SelectBox`}
              list={vitalType}
               placeholder={"Select"}
               value={obstype}
               onchange={(e, value) => handleFormState("obstype", value)}
               error={error?.obstype}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Default_SelectBox_grid`}
            md="2"
            lg="2"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Default_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Control Type 
            </Typography>
            <SelectBox
              id={`${parent_id}_defaultUnit_SelectBox`}
              parent_id={`${parent_id}_defaultUnit_SelectBox`}
              list={controlType}
              placeholder={"Select"}
              value={resultreport}
              onchange={(e, value) => handleFormState("resultreport", value)}
            />
          </Grid>
          <Grid
            id={`${parent_id}_Long_input_grid`}
            md="2"
            lg="6"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Long_label_typography`}
              className={classes.formtitle}
              variant="body1"
            >
                Control Value
            </Typography>
            <TextField
              id={`${parent_id}_Long_textField`}
              value={resultcodeset}
              //onKeyPress={(e) => txtFieldChange(e)}
              onChange={(e) => handleFormState("resultcodeset", e.target.value)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              // error={error?.longdes}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default VitalCodeDescription;
