import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Col, Div, Row, TextInput } from 'qdm-component-library';
import { CommonTabList, SelectBox } from '../../common';
import { AlertProps, AxiosCall, extractIdAndValue, upsertapihubentity } from '../../../utils';
import { AlertContext } from '../../../contexts';
import Config from '../../../config';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    addBtn: {
        float: 'right',
        fontSize: '14px',
        fontWeight: 600,
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: 'auto',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        marginTop: '70px',
        marginLeft: '0px',
        marginRight: '0px',
    },
    topNavbar: {},
    sideNavbar: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hovarNavBar: {
        display: 'flex',
        position: 'relative',
        top: '63px',
    },
    commanTabbar: {
        display: 'flex',
        position: 'relative',
        top: '70px',
        marginLeft: '80px',
    },
   ".MuiSvgIcon-root" :{
     display:"none"
    },
}));

const styles = {
    labelStyle: {
        color: '#6F6F6F',
        fontSize: '12px',
        marginBottom: '6px',
        fontFamily: 'pc_regular !important',
    },
    borderStyle: {
        borderRadius: 8,
        borderColor: '#E0E0E0',
    },
    errorBorderStyle: {
        borderRadius: 8,
        border: '1px solid red',
    },
};

// const services = [
//     { id: '1', label: 'Internal', value: 'Internal' },
//     { id: '2', label: 'External', value: 'External' },
// ];

const ApihubConfi = ({ formState, setFormState, ...props }) => {
    const classes = useStyles();
    const alert = useContext(AlertContext);
    const { labelStyle, borderStyle, errorBorderStyle } = styles;

    // State to manage all form fields


    // State to manage form errors
    const [error, setError] = useState({});
    //State to hide field 
    const [hideField, sethideField] = useState(false);
    const [hideQdmfield, sethideQdmfield] = useState(false);
    const [formError, setformError] = React.useState(false);
    // state for dropdown
    const [collectionList, setCollectionList] = React.useState([]);
    const [DataSource, setDataSource] = React.useState([]);
    const [GeneralMaster, setGeneralMaster] = React.useState([]);
    const [FunctionList, setFunctionList] = React.useState([]);
    const [apiList, setapiList] = React.useState([]);
    const [getId, setgetId] = React.useState();

    useEffect(() => {
        setFormState(prevState => ({
            ...prevState,
            functionality: "",
        }));
    }, [formState.module]);

    useEffect(() => {
        setFormState({
            ...formState,
            arangoQuery: '',
            module: '',
            functionality: '',
            userId: '',
            password: '',
            api: '',
        })
    }, [formState?.dataSourceType?._id]);
    //GET Dropdown Api
    const getCollectionName = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                docType: "DOCUMENT",
                excludeDefault: false,
                metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
            };
            let Resp = await AxiosCall(
                payload,
                process.env.REACT_APP_GET_COLLECTION_LIST
            );
            if (Resp) {
                const list = [];
                Resp?.Result?.map((v, i) => {
                    let obj = {
                        _id: v?._id,
                        label: v?.name,
                        value: v?.name,
                        type: v?.type,
                    };
                    list.push(obj);
                });
                setCollectionList(list);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // API for DataSourceType dropdown \\
    const getDataSource = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["DATASOURCETYPE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setDataSource(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // API for Module dropdown \\
    const getGeneralMaster = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    type: ["PCMODULE"],
                    tenantid: process.env.REACT_APP_TENANTID,
                    facilityid: "",
                    lang: "en",
                },
                queryid: process.env.REACT_APP_RULE_BUILDER_FACT_GM,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                setGeneralMaster(Resp);
            }
        } catch (e) {
            console.error(e);
        }
    };

    //  API for Functionality dropdown \\
    const getFunctionList = async (id) => {

        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {
                    "moduleid": id,
                },
                queryid: Config.getFunctionList,
            }
            let Resp = await AxiosCall(payload, process.env.REACT_APP_READ_QDM_QUERY);
            if (Resp) {
                let result = [];
                Resp?.map((valueItem) => {
                    let obj = {
                        _id: valueItem?._id,
                        value: valueItem?.display,
                        label: valueItem?.display,
                    }
                    result.push(obj);
                });
                setFunctionList(result);
                return result;
            }
        } catch (e) {
            console.error(e);
        }
    };
    ///API for api dropdown///
    const getapiList = async () => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                queryid: Config.getapiList
            };
            let Resp = await AxiosCall(
                payload,
                process.env.REACT_APP_READ_QDM_QUERY
            );
            if (Resp) {
                const list = [];
                Resp?.map((v, i) => {
                    let obj = {
                        _id: v?._id,
                        label: v?.apiname,
                        value: v?.apiname,
                    };
                    list.push(obj);
                });
                setapiList(list);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const geteditApi = async () => {
        let editId = props.editValue[0]._id
        try {
            let payload = {
                db_name: process.env.REACT_APP_DATABASE_NAME,
                filter: {

                    _id: editId
                },
                queryid: Config.geteditApi
            };
            let Resp = await AxiosCall(
                payload,
                process.env.REACT_APP_READ_QDM_QUERY
            );
            if (Resp) {
                Resp.map((val) => {
                    setgetId(val.queryid)
                    let obj = {
                        id: val.datasourcetype._id,
                        label: val.datasourcetype.display,
                        value: val.datasourcetype.display
                    };
                    let Func = {
                        id: val.functionality?._id,
                        label: val.functionality?.display,
                        value: val.functionality?.display
                    }
                    setFormState(
                        {
                            id: val?.queryid,
                            description: val?._desc,
                            dataSourceType: obj,
                            arangoQuery: val?.arangoQuery,
                            module: '',
                            functionality: Func,
                            userId: val?.userid,
                            password: val?.password,
                            api: val?.api,
                        }
                    )
                    getApi(val.api);
                })
            }

        } catch (e) {
            console.error(e);
        }
    };

    const getApi = async (id) => {
        try {
            let payload = {
                db_name: process.env.REACT_APP_METADATA_DB_NAME,
                filter: {
                    _id: id
                },
                queryid: Config.getApi
            };
            let Resp = await AxiosCall(
                payload,
                process.env.REACT_APP_READ_QDM_QUERY
            );
            if (Resp) {
                Resp.map((val) => {
                    let objapi = {
                        id: val._id,
                        label: val.apiname,
                        value: val.apiname
                    };
                    // setFormState(  
                    //     {
                    //         api: objapi,
                    //     }
                    // )

                    setFormState((prevState) => ({
                        ...prevState,
                        api: objapi,
                    }));
                })
            }

        } catch (e) {
            console.error(e);
        }
    };

    React.useEffect(() => {
        getCollectionName();
        getDataSource();
        getGeneralMaster();
        getapiList();
        if (props?.editValue?.length > 0) {
            geteditApi();
        }
       
    }, []);
    // Handle input change for all fields
    const handleChange = (key, value) => {
        getFunctionList(value._id)
        setFormState({
            ...formState,
            [key]: value,
        });
        if (key == "dataSourceType") {
            if (value.value == "Internal") {
                sethideField(true)
            }
            else {
                sethideField(false)
                sethideQdmfield(true)
            }
        }
    };
    // Handle SelectBox value change
    const onSave = async () => {
        if (!formState?.description || !formState?.dataSourceType) {
            setformError(true);  // Set form error state to true
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill in the mandatory fields (Description and DataSourceType).",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            return;  // Prevent form submission or further processing if validation fails
        }
        if (formState?.arangoQuery) {
            const isValidQuery = validateArangoQuery(formState.arangoQuery);
            if (!isValidQuery) {
                alert.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Invalid Arango Query",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                });
                return;
            }
        }
        //edit payload//
        let payload
        if (props?.editValue?.length) {
            if (formState?.dataSourceType?.label === "Internal") {
                payload = [
                    {
                        db_name: process.env.REACT_APP_DATABASE_NAME,
                        entity: upsertapihubentity,
                        is_metadata: false,
                        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                        metadataId: process.env.REACT_APP_METADATAID,
                        filter: {
                            _id: props?.editValue?.[0]?._id
                        },
                        doc: {
                            _desc: formState.description,
                            arangoQuery: "" || formState.arangoQuery,
                            queryid: formState.queryid,
                            activestatus: true,
                            datasourcetype: formState.dataSourceType._id,
                        },
                    },
                ];
            } else {
                payload = [
                    {
                        db_name: process.env.REACT_APP_DATABASE_NAME,
                        entity: upsertapihubentity,
                        is_metadata: false,
                        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                        metadataId: process.env.REACT_APP_METADATAID,
                        filter: {
                            _id: props?.editValue?.[0]?._id
                        },
                        doc: {
                            _desc: formState.description,
                            queryid: formState.queryid,
                            activestatus: true,
                            datasourcetype: formState.dataSourceType._id,
                            functionality: formState.functionality._id,
                            userid: formState.userId,
                            password: formState.password,
                            api: formState.api._id
                        },
                    },
                ];
            }
        }
        ///save payload///
        else {
            if (formState?.dataSourceType?.label === "Internal") {
                //Internal upsert//
                payload = [
                    {
                        db_name: process.env.REACT_APP_DATABASE_NAME,
                        entity: upsertapihubentity,
                        is_metadata: false,
                        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                        metadataId: process.env.REACT_APP_METADATAID,
                        doc: {
                            _desc: formState.description ?? "",
                            arangoQuery: formState.arangoQuery ?? "",
                            queryid: formState.queryid ?? "",
                            activestatus: true,
                            datasourcetype: formState.dataSourceType._id ?? "",
                        },
                    },
                ];
            }
            //External Upsert//
            else {
                payload = [
                    {
                        db_name: process.env.REACT_APP_DATABASE_NAME,
                        entity: upsertapihubentity,
                        is_metadata: false,
                        metadata_dbname: process.env.REACT_APP_METADATA_DB_NAME,
                        metadataId: process.env.REACT_APP_METADATAID,
                        doc: {
                            _desc: formState.description ?? "",
                            arangoQuery: formState.arangoQuery ?? "",
                            queryid: formState.queryid ?? "",
                            datasourcetype: formState.dataSourceType._id,
                            functionality: formState.functionality._id ?? "",
                            userid: formState.userId ?? "",
                            password: formState.password ?? "",
                            api: formState.api._id ?? "",
                        },
                    },
                ];
            }
        }
        let Resp = await AxiosCall(payload, process.env.REACT_APP_UPSERT_DOC);
        if (Resp.Code === 201) {
            props?.getApiConfigData();
            alert?.setSnack({
                open: true,
                severity: AlertProps.severity.success,
                msg: `${props?.editValue?.length > 0 ? "update" : "Save"} Successful`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
                tone: true,
            });
            setFormState(
                {
                    id: "",
                    description: "",
                    dataSourceType: "",
                    arangoQuery: "",
                    module: "",
                    functionality: "",
                    userId: "",
                    password: "",
                    api: "",
                }
            )
        }
        props.setisFormOpen({
            isView: false,
            isEdit: false,
            isForm: false,
        })
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const validateArangoQuery = (query) => {
        const queryPattern = /FOR\s+\w+\s+IN\s+\w+\s+FILTER\s+.*\s+RETURN\s+\w+/;
        return queryPattern.test(query.trim());
    };

    const onCancel = () => {
        props.setisFormOpen(false)
    }

    return (
        <div className={classes.root}>
            {/* Your nav bars here */}
            <Div>
                <CommonTabList
                    parent_id={'admintabpanel'}
                    title='API HUB CONFIGURATION'
                    list={[]}
                    backbtn={true}
                    backFun={() => {
                        props?.setisFormOpen({
                            ...props?.isFormOpen,
                            isForm: false,
                            isView: false,
                            isEdit: false,
                        });
                    }}
                />
            </Div>

            <Div className='pi-paper'>
                <Grid container spacing={2} direction='column'>
                    <Grid item>
                        <Paper>
                            <Div style={{ padding: '20px' }}>
                                <Div>
                                    <Row>
                                        {/* {!props?.isFormOpen?.isForm ? ( */}
                                            <Col lg={4} inLineStyles={{ padding: 10 }}>
                                                <TextInput
                                                    label="Id"
                                                    placeholder=""
                                                    labelStyle={{ ...labelStyle, marginLeft: '5px' }}
                                                    value={formState.id} // This will display the generated UUID
                                                    type="text"
                                                    name="id"
                                                    disabled
                                                    style={error?.eventcode ? errorBorderStyle : borderStyle}
                                                />
                                            </Col>
                                        {/* ) : (<></>)} */}
                                        <Col lg={4} inLineStyles={{ padding: 10 }}>
                                            <TextInput
                                                label={
                                                    <>
                                                        Descriotion<span style={{ color: "red" }}> *</span>
                                                    </>
                                                }
                                                labelStyle={labelStyle}
                                                value={formState?.description}
                                                name='description'
                                                onChange={(e, v) => {
                                                    handleChange('description', e.target.value);
                                                }}
                                                placeholder='Enter Description'
                                                style={
                                                    formError && !formState?.description
                                                        ? errorBorderStyle
                                                        : borderStyle
                                                }
                                                disabled={
                                                    props.isFormOpen.isView
                                                }
                                            />
                                        </Col>

                                        <Col lg={4} inLineStyles={{ padding: 12 }}>
                                            <Typography
                                                style={{
                                                    fontSize: '12px',
                                                    fontFamily: 'pc_regular',
                                                    color: '#6F6F6F',
                                                }}
                                                variant='body1'
                                            >
                                                Data Source Type<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <SelectBox
                                                size='large'
                                                labelStyle={labelStyle}
                                                value={formState?.dataSourceType}
                                                name='dataSourceType'
                                                placeholder='Select Type'
                                                list={extractIdAndValue("DATASOURCETYPE", DataSource)}
                                                onchange={(e, v) => {
                                                    handleChange('dataSourceType', v);
                                                }}
                                                error={
                                                    (formError && !formState?.dataSourceType.value)
                                                }
                                                disabled={
                                                    props.isFormOpen.isView
                                                }
                                            />
                                        </Col>

                                        {formState?.dataSourceType?.value == "Internal" ? (
                                            <Col md={2} lg={12} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    QDM Query
                                                </Typography>
                                                <TextField
                                                    value={formState?.arangoQuery}
                                                    name='qdmQuery'
                                                    labelStyle={labelStyle}
                                                    placeholder='Enter Arango Query'
                                                    multiline
                                                    minRows={6}
                                                    variant='outlined'
                                                    style={{ width: '100%' }}
                                                    onChange={(e, v) => {
                                                        handleChange('arangoQuery', e.target.value);
                                                    }}
                                                    disabled={
                                                        // hideQdmfield || props.queryHide || props.isFormOpen.isView
                                                        formState?.dataSourceType?.label != "Internal"
                                                    }
                                                />

                                            </Col>
                                        ) : (<></>)}

                                        {formState?.dataSourceType?.value == "External" ? (
                                            <Col
                                                lg={6} inLineStyles={{ padding: 10 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    Module
                                                </Typography>
                                                <SelectBox
                                                    size='large'
                                                    labelStyle={labelStyle}
                                                    value={formState?.module}
                                                    name='module'
                                                    placeholder='Select Module'
                                                    list={extractIdAndValue("PCMODULE", GeneralMaster)}
                                                    onchange={(e, v) => {
                                                        handleChange('module', v);
                                                    }}
                                                    disabled={
                                                        // hideField || props.isFormOpen.isEdit || props.isFormOpen.isView
                                                        formState?.dataSourceType?.label === "Internal"
                                                    }
                                                />
                                            </Col>
                                        ) : (<></>)}


                                        {formState?.dataSourceType?.value == "External" ? (
                                            <Col lg={6} inLineStyles={{ padding: 10 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    Functionality
                                                </Typography>
                                                <SelectBox
                                                    size='large'
                                                    labelStyle={labelStyle}
                                                    value={formState?.functionality}
                                                    name='functionality'
                                                    placeholder='Select Functionality'
                                                    list={FunctionList}
                                                    onchange={(e, v) => {
                                                        handleChange('functionality', v);
                                                    }}
                                                    disabled={
                                                        // hideField || props.isFormOpen.isEdit || props.isFormOpen.isView
                                                        formState?.dataSourceType?.label === "Internal"
                                                    }
                                                />
                                            </Col>
                                        ) : (<></>)}

                                        {formState?.dataSourceType?.value == "External" ? (
                                            <Col lg={4} inLineStyles={{ padding: 10 }}>
                                                <TextInput
                                                    label='User id'
                                                    labelStyle={labelStyle}
                                                    value={formState?.userId}
                                                    name='userId'
                                                    onChange={(e, v) => {
                                                        handleChange('userId', e.target.value);
                                                    }}
                                                    placeholder='Enter Userid'
                                                    disabled={
                                                        // hideField || props.isFormOpen.isEdit || props.isFormOpen.isView
                                                        formState?.dataSourceType?.label === "Internal"
                                                    }
                                                />
                                            </Col>
                                        ) : (<></>)}

                                        {formState?.dataSourceType?.value == "External" ? (
                                            <Col lg={4} inLineStyles={{ padding: 10 }}>
                                                <TextInput
                                                    label='Password'
                                                    labelStyle={labelStyle}
                                                    value={formState?.password}
                                                    name='password'
                                                    onChange={(e, v) => {
                                                        handleChange('password', e.target.value);
                                                    }}
                                                    placeholder='Enter Password'
                                                    disabled={
                                                        // hideField || props.isFormOpen.isEdit || props.isFormOpen.isView
                                                        formState?.dataSourceType?.label === "Internal"
                                                    }
                                                />
                                            </Col>
                                        ) : (<></>)}

                                        {formState?.dataSourceType?.value == "External" ? (
                                            <Col lg={4} inLineStyles={{ padding: 10 }}>
                                                <Typography
                                                    style={{
                                                        fontSize: '12px',
                                                        fontFamily: 'pc_regular',
                                                        color: '#6F6F6F',
                                                    }}
                                                    variant='body1'
                                                >
                                                    API
                                                </Typography>
                                                <SelectBox
                                                    size='large'
                                                    labelStyle={labelStyle}
                                                    value={formState?.api}
                                                    name='api'
                                                    placeholder='Enter API'
                                                    list={apiList}
                                                    onchange={(e, v) => {
                                                        handleChange('api', v);
                                                    }}
                                                    disabled={
                                                        // hideField || props.isFormOpen.isEdit || props.isFormOpen.isView
                                                        formState?.dataSourceType?.label === "Internal"
                                                    }
                                                />
                                            </Col>
                                        ) : (<></>)}
                                    </Row>
                                </Div>
                            </Div>
                        </Paper>
                    </Grid>

                    <Grid id={'agerange-form-rangedetails-grid'} style={{ padding: '20px' }}>
                        <Button
                            id={'agerange_form_rangedetails_Save_button'}
                            style={{ float: 'right' }}
                            variant='contained'
                            color='primary'
                            onClick={onSave}
                        >
                            {props?.editValue?.length ? "Update" : "Save"}
                        </Button>
                        <Button
                            id={'agerange_form_rangedetails_Cancel_button'}
                            style={{ float: 'right', marginRight: '20px' }}
                            variant='outlined'
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Div>
        </div>
    );
};

export default ApihubConfi;
