import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import HolidayTable from "./holidayTable";
import TimeTableTable from "./timetableTable";
import ResourcebyTypeTable from "./resourceAppointmentTable";
// import AppointmentTypeTable from "./appointmentTable";
import AppointmentTypeTableV2 from "./appointmentTableV2";
import ScheduleManagement from "./scheduleManagement/index";
import "../../components/font.css";
import { CommonTabList } from "../../components/common";
import { checkWithCasbin } from "../../utils";
import { withRouter } from "react-router-dom";
import HolidayTableVersion2 from "./holidayTableVersion2";
import TimeTableManagement from "./timeTableManagement";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,

    "& .MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .MuiAppBar-root": {
      width: "80%",
      // height: "56px",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px",
    paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
  },
}));
function AvailabilityTabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  useEffect(() => {
    console.log("availabilityData", props.availabilityData);
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.box}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

AvailabilityTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function ScrollableTabsButtonAuto(props) {
  console.log(props, "props");
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [listData, setListData] = React.useState([]);

  const json = {
    holiday: {
      name: "Holiday",
      component: <HolidayTableVersion2 parent_id={"HolidayTable"} />,
    },
    // timetable: {
    //   name: "Time Table",
    //   component: (
    //     <TimeTableTable
    //       parent_id={"PRACTROLE"}
    //       type={"PRACTROLE"}
    //       header={"Practitioner Role"}
    //     />
    //   ),
    // },
    timetableng: {
      name: "Time Table",
      component: (
        <TimeTableManagement
          parent_id={"PRACTROLE"}
          type={"PRACTROLE"}
          header={"Practitioner Role"}
        />
      ),
    },
    // resourcebyAppointmentTypes: {
    //   name: "Resource by appointment type",
    //   component: (
    //     <ResourcebyTypeTable
    //       parent_id={"PRACTTYPE"}
    //       type={"PRACTTYPE"}
    //       header={"Resource by Appointment Types"}
    //     />
    //   ),
    // },
    appointmentTypes: {
      name: "Appointment type",
      component: (
        <AppointmentTypeTableV2
          parent_id={"QUALIFICATION"}
          type={"QUALIFICATION"}
          header={"Appointment Types"}
        />
      ),
    },
    scheduleManagement: {
      name: "Schedule Management",
      component: <ScheduleManagement parent_id={"Schedule-Management"} />,
    },
  };
  React.useEffect(() => {
    const baseFun = async () => {
      let permission = await checkWithCasbin(["availabilityManagement"]);
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      let arr = [];
      console.log(permission, "kksjjjdurt");

      var unique = [...permission.read, ...permission.write].filter(onlyUnique);
      unique.map((val) => {
        arr.push(json[val]);
      });
      setListData(arr);
    };
    baseFun();
  }, []);

  return (
    <Grid id={"availability-parent-grid"} container spacing={0}>
      <Grid id={"availability-parent-sub-grid"} item md={12}>
        <div id={"availability-parent-div"} className={classes.root}>
          {/* New Code.......................... */}

          <CommonTabList
            parent_id={"Availability-Management"}
            title="Availability Management"
            list={listData}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(ScrollableTabsButtonAuto);
