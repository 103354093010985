import React, { useEffect } from 'react'
import { HoverNavBar, TopNavBar } from '../../navbars'
import { Div } from 'qdm-component-library'
import { Box, Grid, makeStyles } from '@material-ui/core';
import ApiConfigList from '../apiconfiguration/apiConfigList';
import { CommonTabList } from '../../common';
import ApihubConfiList from '../apihubconfiguration/apihubConfiList';
import PropTypes from "prop-types";
import ApiConnectorList from '../apiConnector/apiConnectorList';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // width: "100%",
        marginTop:"70px",
        marginLeft:"70px",
        backgroundColor: theme.palette.background.paper,

        "& .MuiTab-textColorPrimary": {
            "&.Mui-selected": {
                color: "#0071F2",
            },
        },
        "&.MuiPaper-elevation4": {
            shadow: "none",
        },
        "& .MuiTab-root": {
            [theme.breakpoints.up("xs")]: {
                minWidth: "inherit !important",
            },
        },
        "& .MuiAppBar-root": {
            width: "80%",
            // height: "56px",
            zIndex: "0",
        },
    },
    addBtn: {
        float: "right",
        fontSize: "14px",
        fontWeight: 600,
    },
    content: {
        width: "98%",
        height: "calc(100vh - 64px)",
        overflow: "auto",
        // [theme.breakpoints.up("sm")]: {
        //     paddingLeft: 56,
        // },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
        },
        // marginTop: "70px",
        // marginLeft: "30px",
    },
    topNavbar: {},
    sideNavbar: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    hovarNavBar: {
        display: "flex",
        position: "relative",
        top: "63px",
    },
    commanTabbar: {
        display: "flex",
        position: "relative",
        top: "70px",
        marginLeft: "80px"
    }
}));
function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;
    useEffect(() => {
    });
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={classes.box}>
            <Grid>{children}</Grid>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const ApiHub = () => {
    const classes = useStyles();


    return (
        <div>
            <>
        <div className={classes.topNavbar}>
          <TopNavBar />
        </div>

        <div className={classes.hovarNavBar}>
          <HoverNavBar />
        </div>
      </>
        <Grid container spacing={0}>
        <Grid item md={12}>
          <div className={classes.root}>
                    <div className={classes.root}>
                        <CommonTabList
                            title="API Hub"
                            list={[
                                {
                                    name: "Connector",
                                    component: (
                                        <ApiConnectorList
                                        header={"Configuration"}
                                        />
                                    )
                                },
                                {
                                    name: "API List",
                                    component: (
                                        <ApiConfigList
                                            type={"APICONFIG"}
                                            header={"Api Configuration"}
                                        />
                                    ),
                                },
                                {
                                    name: "Query Manager",
                                    component: (
                                        <ApihubConfiList
                                            type={"APIHUBCONFIG"}
                                            header={"Api Hub Configuration"}
                                        />
                                    ),
                                },

                            ]}
                        />
                    </div>
                    </div>
      </Grid>
    </Grid>
    </div>
    )
}

export default ApiHub